import React from 'react'
import './GemRegistrationPage.css'

const GemRegistrationPage = () => {
  return (
    <div>
    <div className="GemCps-MainCard-div">

      <div className="GemCpsCircle-card-div-1">
        <div className="card-circle-Gem-1"></div>
        <div className="GemCps-Steps-card-1">
        <p className="GemCps-Card-Text">
        Start Your Journey with registration to become a GEM Certfied Professional
        </p>
        </div>
      </div>

      <div className="GemCpsCircle-card-div-2">
        <div className="card-circle-Gem-2"></div>
        <div className="GemCps-Steps-card-2">
        <p className="GemCps-Card-Text">
        Once your registration is successful, you will receive the following link to make the payment for GEM CP online test fee: <br /> Fee details: - Rs. 5,900 for Professionals <br /> - Rs. 4,720 for Faculties & Students (Including GST) Payment options available. You can also contact us for cheque/DD/NEFT payments.
        </p>
        </div>
      </div>

      <div className="GemCpsCircle-card-div-3">
        <div className="card-circle-Gem-3"></div>
        <div className="GemCps-Steps-card-3">
        <p className="GemCps-Card-Text">
        After payment, you will receive a link to schedule your online test. You can schedule the test between Monday to Friday from 10 AM to 5 PM.
        </p>
        </div>
      </div>

      <div className="GemCpsCircle-card-div-4">
        <div className="card-circle-Gem-4"></div>
        <div className="GemCps-Steps-card-4">
        <p className="GemCps-Card-Text">
        The GEM Sustainability Certification Program reference guide can be downloaded from our website for FREE
        </p>
        </div>
      </div>

      <div className="GemCpsCircle-card-div-5">
        <div className="card-circle-Gem-5"></div>
        <div className="GemCps-Steps-card-5">
        <p className="GemCps-Card-Text">
        You will receive a link to take the online test as per your scheduled time
        </p>
        </div>
      </div>


    </div>

    </div>
  )
}

export default GemRegistrationPage
