
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BillingInfo.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';
import BillingModels from './BillingModels';

const countryStateData = {
  India: [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 
    'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 
    'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 
    'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 
    'Delhi', 'Puducherry', 'Jammu and Kashmir', 'Ladakh',
  ],
};

const countryCodes = {
  India: '+91',
};

const BillingInfo = () => {
  const [selectedProject, setSelectedProject] = useState('');
  const [subProject, setSubProject] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('India');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobile, setMobile] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [hasCertification, setHasCertification] = useState(false);
  const [certification, setCertification] = useState('');
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track if the form is submitted
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [captcha, setCaptcha] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    setState('');
  }, [country]);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setState('');
  };
  const validatePhone = (mobile) => {
    const phonePattern = /^\d{10}$/; // Must be 10 digits
    const invalidPatterns = ['1234567890', '0987654321', '0000000000'];
    return phonePattern.test(mobile) && !invalidPatterns.includes(mobile);
  };

  const validateName = (name) => {
    const namePattern = /^[A-Za-z ]+$/; // Name should only contain alphabets and spaces
    return namePattern.test(name);
  };

  const validateCity = (city) => {
    const cityPattern = /^[A-Za-z ]+$/; // City should only contain alphabets and spaces
    return cityPattern.test(city);
  };

  const validatePincode = (pincode) => {
    const pincodePattern = /^[0-9]{6}$/; // Pincode should be 6 digits
    return pincodePattern.test(pincode);
  };

  // const handleCaptchaChange = (value) => {
  //   if (value) {
  //     setCaptchaVerified(true);
  //   } else {
  //     setCaptchaVerified(false);
  //   }
  // };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in firstName
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setFirstName(value);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in lastName
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setLastName(value);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in city
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setCity(value);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ''); // Allow only numeric values

    if (numericValue.length <= 10) { // Only allow a maximum of 10 digits
      setMobile(numericValue);
    }
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ''); // Allow only numeric values

    if (numericValue.length <= 6) { // Only allow a maximum of 6 digits
      setPincode(numericValue);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate first and last name
    if (!validateName(firstName)) {
      toast.error('First Name should only contain alphabets and spaces');
      return;
    }

    if (!validateName(lastName)) {
      toast.error('Last Name should only contain alphabets and spaces');
      return;
    }

    if (!validateCity(city)) {
      toast.error('City should only contain alphabets and spaces');
      return;
    }

    if (!validatePhone(mobile)) {
      toast.error('Your Phone Number is invalid');
      return;
    }

    if (!validatePincode(pincode)) {
      toast.error('Pincode should be 6 digits');
      return;
    }

    //   if (!captcha) {
    //   toast.error('Please verify that you are not a robot');
    //   return;
    // }
    if (!cart || cart.length === 0) {
      toast.error('Please select at least one exam.');
      return;
    }
    const formData = {
      firstName,
      lastName,
      email,
      address,
      country,
      state,
      city,
      pincode,
      mobile,
      telephone,
      message,
      hasCertification,
      certification,
      cart: JSON.parse(localStorage.getItem('cart')),
      // captcha,
    };

    localStorage.setItem('registerCandidateInfo', JSON.stringify(formData));

  try {
    // Post form data to the backend
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/billing/billinginfo`, formData);
    
    // Show success message if response is successful
    toast.success(response.data.message || 'Form submitted successfully!');
    setShowModal(true);
    handleCancel(); // Clear the form after successful submission
  } catch (error) {
    // If there's an error, check if it's a validation error (e.g., email already registered)
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);  // Display error message received from backend
    } else {
      // General error message
      toast.error('Error submitting form. Please try again.');
    }
  }
};

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);  // Remove item from the cart by index
    setCart(updatedCart);  // Update the cart state
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.amount, 0);
  };

  const handleCancel = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setAddress('');
    setCountry('India');
    setState('');
    setCity('');
    setPincode('');
    setMobile('');
    setTelephone('');
    setMessage('');
    setHasCertification(false);
    setCertification('');
    setCart([]);
    // setCaptcha(); 
  };

  const handleLoginSubmit = async () => {
    // Validate credentials against the billing_info table (e.g., using an API call)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/billing/validate`, { username, password });
      if (response.data.isAuthenticated) {
        toast.success('Authenticated successfully!');
        localStorage.setItem('userCredentials', JSON.stringify(response.data.user)); // Store user info
        // Redirect to the PaymentSubmit page or load it here
        window.location.href = '/payment'; // Or use a React Router navigation
      } else {
        toast.error('Invalid username or password');
      }
    } catch (error) {
      toast.error('Error authenticating user');
    }
  };


  return (
    <div>
      <div className="GEM-form-div-bkg" id='GEMBilling-form-div-bkg'>
        <div className="mainBilling-form-div-popup">
          <div className="billing-form">
            <form onSubmit={handleSubmit}>
              <h3 className="GemBilling-form-heading">Candidate Information</h3>
              <div className="form-fields">
                <div className="form-fields-data">
                  <label>First Name *</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    required
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Email Id *</label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Address Line *</label>
                  <input
                    type="text"
                    placeholder="Address Line"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Country *</label>
                  <select value={country} onChange={handleCountryChange} required>
                    <option value="" disabled>
                      Select your country
                    </option>
                    {Object.keys(countryStateData).map((countryName, index) => (
                      <option key={index} value={countryName}>
                        {countryName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-fields-data">
                  <label>State *</label>
                  <select value={state} onChange={(e) => setState(e.target.value)} required>
                    <option value="" disabled>
                      Select your state
                    </option>
                    {country === 'India' ? (
                      countryStateData[country].map((stateName, index) => (
                        <option key={index} value={stateName}>
                          {stateName}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Select a country to see states
                      </option>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>City *</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={handleCityChange}
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Pincode *</label>
                  <input
                    type="text"
                    placeholder="Pin Code"
                    value={pincode}
                    onChange={handlePincodeChange}
                    required
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data flex-container">
                  <label>Mobile No. *</label>
                  <input
                    className="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={mobile}
                    onChange={handleMobileChange}
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Telephone No. (With STD Code)</label>
                  <input
                    type="tel"
                    placeholder="Telephone Number"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
              </div>

              {/* Add reCAPTCHA above the cart */}
              {/* <div className="captcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  onChange={(value) => setCaptcha(value)}
                />
              </div>  */}

              {/* Cart Data */}
              {cart.length > 0 && (
                <div className="cart-container" id="SelectedBillingItem">
                  <h3>Your Selected Exam.</h3>
                  <table id='billingTable'>
                    <thead>
                      <tr>
                        <th style={{textAlign: 'center'}}>Title</th>
                        <th>Sub Total</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item, index) => (
                        <tr key={index}>
                          <td>{item.title}</td>
                          <td>{item.amount}</td>
                          <td>
                            <button onClick={() => removeFromCart(index)}>Remove</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="total-container">
                    <h3>Total: ₹{calculateTotal()}</h3>
                  </div>
                </div>
              )}

              <div className="button-group-2">
                <button type="submit" className="submit-btn" onSubmit={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <BillingModels
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        formData={{ firstName}}
      />
        <ToastContainer />
      </div> 
    </div> 
  );
};
export default BillingInfo;
