


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Register2.css';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import ReCAPTCHA from 'react-google-recaptcha';

// const countryStateData = {
//   India: [
//     'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
//     'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 
//     'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 
//     'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
//     'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 
//     'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 
//     'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 
//     'Delhi', 'Puducherry', 'Jammu and Kashmir', 'Ladakh',
//   ],
// };

// const countryCodes = {
//   India: '+91',
// };

// const Register2 = () => {
//   const [selectedProject, setSelectedProject] = useState('');
//   const [subProject, setSubProject] = useState('');
//   const [salutation, setSalutation] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [email, setEmail] = useState('');
//   const [address, setAddress] = useState('');
//   const [country, setCountry] = useState('India'); // Default country set to India
//   const [state, setState] = useState('');
//   const [city, setCity] = useState('');
//   const [pincode, setPincode] = useState('');
//   const [mobile, setMobile] = useState('');
//   const [telephone, setTelephone] = useState('');
//   const [message, setMessage] = useState('');
//   const [hasCertification, setHasCertification] = useState(false);
//   const [certification, setCertification] = useState('');
//   const [captcha, setCaptcha] = useState(null);

//   const projectOptions = {
//     'Green building Certification': ['New Building', 'Existing Building', 'Factory Shed', 'Hospital', 'Educational Institute', 'Hotels', 'Banks', 'Others', 'Railway Stations', 'Airports', 'Ports and Harbours', 'Green Interiors'],
//     'Gem Eco Product': ['1', '2', '3', '4', '5'],
//     'carbon footprint': ['A', 'B', 'C', 'D'],
//     'Sustainability Assessment': ['AB', 'AC', 'AD', 'AE', 'AG'],
//   };

//   const certificationOptions = ['ISO2001', 'ISO2002', 'ISO2003'];

//   useEffect(() => {
//     setState(''); // Reset state when country changes
//   }, [country]);

//   const handleProjectChange = (e) => setSelectedProject(e.target.value);
  
//   const handleCountryChange = (e) => {
//     setCountry(e.target.value);
//     setState(''); // Reset state when country changes
//   };

//   const validatePhone = (mobile) => {
//     const phonePattern = /^\d{10}$/; // Must be 10 digits
//     const invalidPatterns = ['1234567890', '0987654321'];
//     return phonePattern.test(mobile) && !invalidPatterns.includes(mobile);
//   };

//   const validateName = (name) => {
//     // Ensure the name does not contain numeric values
//     const namePattern = /^[a-zA-Z\s]+$/;
//     return namePattern.test(name);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate first and last name
//     if (!validateName(firstName)) {
//       toast.error('First Name cannot contain numeric values.');
//       return;
//     }
//     if (!validateName(lastName)) {
//       toast.error('Last Name cannot contain numeric values.');
//       return;
//     }
//     if (!captcha) {
//       toast.error('Please verify that you are not a robot');
//       return;
//     }

//     const formData = {
//       selectedProject,
//       subProject,
//       salutation,
//       firstName,
//       lastName,
//       email,
//       address,
//       country,
//       state,
//       city,
//       pincode,
//       mobile,
//       telephone,
//       message,
//       hasCertification,
//       certification,
//       captcha,
//     };

//     // Save form data to local storage
//     localStorage.setItem('registerFormData', JSON.stringify(formData));

//     // Validation for mobile number
//     if (!validatePhone(formData.mobile)) {
//       toast.error('Your Phone Number is Wrong');
//       return;
//     }

//     try {
//       // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit`, formData);
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/project_registration`, formData);
//       toast.success(response.data.message || 'Form submitted successfully!');
//       handleCancel(); // Clear the form after successful submission
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       toast.error('Error submitting form. Please try again.');
//     }
//   };

//   const handleCancel = () => {
//     setSelectedProject('');
//     setSubProject('');
//     setSalutation('');
//     setFirstName('');
//     setLastName('');
//     setEmail('');
//     setAddress('');
//     setCountry('India'); // Reset country to India
//     setState('');
//     setCity('');
//     setPincode('');
//     setMobile('');
//     setTelephone('');
//     setMessage('');
//     setHasCertification(false);
//     setCertification('');
//     setCaptcha(null);
//   };

//   return (
//     <div>
//       <div className="main-form-div-bkg">
//         <div className="Gem-main-div">
//           <div className="Gem-btn">
//             <a href="/" className="Gem-btn-link">
//               Home <span className="separator"> &gt; </span> Register For GEM
//             </a>
//           </div>
//         </div>
      

//       <div className='main-form-div'>
//         <div className="register-form">
//           <form onSubmit={handleSubmit}>
//             <div className="form-fields">
//               <div className="form-fields-data">
//                 <label>Select Your Project *</label>
//                 <select value={selectedProject} onChange={handleProjectChange} required>
//                   <option value="" disabled>Select a project</option>
//                   {Object.keys(projectOptions).map((project, index) => (
//                     <option key={index} value={project}>{project}</option>
//                   ))}
//                 </select>
//               </div>
         
//               <div className="form-fields-data">
//                 <label>Select Your Sub Project *</label>
//                 <select value={subProject} onChange={(e) => setSubProject(e.target.value)} required>
//                   <option value="" disabled>Select a sub-project</option>
//                   {selectedProject ? (
//                     projectOptions[selectedProject].map((option, index) => (
//                       <option key={index} value={option}>{option}</option>
//                     ))
//                   ) : (
//                     <option value="" disabled>Select a project to see sub-projects</option>
//                   )}
//                 </select>
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data flex-container">
//                 <label>Salutations *</label>
//                 <div className="radio-container">
//                   <div className="radio-item">
//                     <input type="radio" name="salutation" value="Mr" onChange={(e) => setSalutation(e.target.value)} required />
//                     <span>MR</span>
//                   </div>
//                   <div className="radio-item">
//                     <input type="radio" name="salutation" value="Ms" onChange={(e) => setSalutation(e.target.value)} required />
//                     <span>MS</span>
//                   </div>
//                   <div className="radio-item">
//                     <input type="radio" name="salutation" value="Dr" onChange={(e) => setSalutation(e.target.value)} required />
//                     <span>DR</span>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data">
//                 <label>First Name *</label>
//                 <input type="text" placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
//               </div>

//               <div className="form-fields-data">
//                 <label>Last Name *</label>
//                 <input type="text" placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} required />
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data">
//                 <label>Email Id *</label>
//                 <input type="email" placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
//               </div>

//               <div className="form-fields-data">
//                 <label>Address Line *</label>
//                 <input type="text" placeholder='Address Line'  value={address} onChange={(e) => setAddress(e.target.value)} required />
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data">
//                 <label>Country *</label>
//                 <select value={country} onChange={handleCountryChange} required>
//                   <option value="" disabled>Select your country</option>
//                   {Object.keys(countryStateData).map((countryName, index) => (
//                     <option key={index} value={countryName}>{countryName}</option>
//                   ))}
//                 </select>
//               </div>

//               <div className="form-fields-data">
//                 <label>State *</label>
//                 <select value={state} onChange={(e) => setState(e.target.value)} required>
//                   <option value="" disabled>Select your state</option>
//                   {country === 'India' ? (
//                     countryStateData[country].map((stateName, index) => (
//                       <option key={index} value={stateName}>{stateName}</option>
//                     ))
//                   ) : (
//                     <option value="" disabled>Select a country to see states</option>
//                   )}
//                 </select>
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data">
//                 <label>City *</label>
//                 <input type="text" placeholder='City' value={city} onChange={(e) => setCity(e.target.value)} required />
//               </div>

//               <div className="form-fields-data">
//                 <label>Pincode *</label>
//                 <input type="text" placeholder='Pin Code' value={pincode} onChange={(e) => setPincode(e.target.value)} required />
//               </div>
//             </div>

//             <div className="form-fields">
//               <div className="form-fields-data flex-container">
//                 <label>Mobile No. *</label>
//                 <input className='phone' type="text" placeholder='Phone Number' value={mobile} onChange={(e) => setMobile(e.target.value)} required /> 
//               </div>

//               <div className="form-fields-data">
//                 <label>Telephone No. (With STD Code)</label>
//                 <input type="tel" placeholder='Telephone Number' value={telephone} onChange={(e) => setTelephone(e.target.value)} />
//               </div>
//             </div>
           
//             <div className="form-fields">
//               <div className="form-fields-data"> 
//                 <label>Description</label>
//                 <textarea id='textArea' placeholder='Please describe your project' value={message} onChange={(e) => setMessage(e.target.value)} />
//               </div>

//               <div className="is-selected-div">
//                 <div className="form-fields-data flex-container" >
//                   <label>Have you undertaken any other certification? *</label>
//                   <div className="checkbox-container">
//                     <div className="checkbox-item">
//                       <input type="checkbox" checked={hasCertification} onChange={() => setHasCertification(!hasCertification)} />
//                       <span>Yes</span>
//                     </div>
//                     <div className="checkbox-item">
//                       <input type="checkbox" checked={!hasCertification} onChange={() => setHasCertification(!hasCertification)} />
//                       <span>No</span>
//                     </div>
//                   </div>
//                 </div>

//                 {hasCertification && (
//                   <div className="form-fields-data flex-container" id='is-selected-box'>
//                     <label>Select Certification</label>
//                     <select id='is-seleted-input' value={certification} onChange={(e) => setCertification(e.target.value)} required>
//                       <option value="" disabled>Select certification</option>
//                       {certificationOptions.map((option, index) => (
//                         <option key={index} value={option}>{option}</option>
//                       ))}
//                     </select>
//                   </div>
//                 )}
//               </div>
             
//             </div>
//             <div className="captcha-container">
//                 <ReCAPTCHA
//                   sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
//                   onChange={(value) => setCaptcha(value)}
//                 />
//               </div>

//             <div className="button-group-2">
//               <button type="submit" className="submit-btn">Submit</button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <ToastContainer />
//     </div>
//     </div>
//   );
// };

// export default Register2;


// new new code 




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Register2.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

const countryStateData = {
  India: [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 
    'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 
    'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 
    'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 
    'Delhi', 'Puducherry', 'Jammu and Kashmir', 'Ladakh',
  ],
};

const countryCodes = {
  India: '+91',
};

const Register2 = () => {
  const [selectedProject, setSelectedProject] = useState('');
  const [subProject, setSubProject] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('India'); // Default country set to India
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobile, setMobile] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [hasCertification, setHasCertification] = useState(false);
  const [certification, setCertification] = useState('');
  // const [captcha, setCaptcha] = useState(null);
   const [captchaVerified, setCaptchaVerified] = useState(false);

  const projectOptions = {
    'Green building Certification': ['New Building', 'Existing Building', 'Factory Shed', 'Hospital', 'Educational Institute', 'Hotels', 'Banks', 'Others', 'Railway Stations', 'Airports', 'Ports and Harbours', 'Green Interiors'],
    'Gem Eco Product': ['1', '2', '3', '4', '5'],
    'carbon footprint': ['A', 'B', 'C', 'D'],
    'Sustainability Assessment': ['AB', 'AC', 'AD', 'AE', 'AG'],
  };

  const certificationOptions = ['ISO2001', 'ISO2002', 'ISO2003'];

  useEffect(() => {
    setState(''); // Reset state when country changes
  }, [country]);

  const handleProjectChange = (e) => setSelectedProject(e.target.value);
  
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setState(''); // Reset state when country changes
  };

  const validatePhone = (mobile) => {
    const phonePattern = /^\d{10}$/; // Must be 10 digits
    const invalidPatterns = ['1234567890', '0987654321'];
    return phonePattern.test(mobile) && !invalidPatterns.includes(mobile);
  };

  const validateName = (name) => {
    const namePattern = /^[A-Za-z ]+$/; // Name should only contain alphabets and spaces
    return namePattern.test(name);
  };

  const validateCity = (city) => {
    const cityPattern = /^[A-Za-z ]+$/; // City should only contain alphabets and spaces
    return cityPattern.test(city);
  };

  const validatePincode = (pincode) => {
    const pincodePattern = /^[0-9]{6}$/; // Pincode should be 6 digits
    return pincodePattern.test(pincode);
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in firstName
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setFirstName(value);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in lastName
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setLastName(value);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces in city
    const regex = /^[A-Za-z ]*$/;  // Allows letters and spaces only
    if (regex.test(value)) {
      setCity(value);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ''); // Allow only numeric values

    if (numericValue.length <= 10) { // Only allow a maximum of 10 digits
      setMobile(numericValue);
    }
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ''); // Allow only numeric values

    if (numericValue.length <= 6) { // Only allow a maximum of 6 digits
      setPincode(numericValue);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate first and last name
    if (!validateName(firstName)) {
      toast.error('First Name should only contain alphabets and spaces');
      return;
    }

    if (!validateName(lastName)) {
      toast.error('Last Name should only contain alphabets and spaces');
      return;
    }

    if (!validateCity(city)) {
      toast.error('City should only contain alphabets and spaces');
      return;
    }

    if (!validatePhone(mobile)) {
      toast.error('Your Phone Number is invalid');
      return;
    }

    if (!validatePincode(pincode)) {
      toast.error('Pincode should be 6 digits');
      return;
    }

  if (!captchaVerified) {
        toast.error('Please complete the CAPTCHA');
        return;
      }
   
    const formData = {
      selectedProject,
      subProject,
      salutation,
      firstName,
      lastName,
      email,
      address,
      country,
      state,
      city,
      pincode,
      mobile,
      telephone,
      message,
      hasCertification,
      certification,
      // captcha,
    };

    // Save form data to local storage
    localStorage.setItem('registerFormData', JSON.stringify(formData));

    // // Validation for mobile number
    // if (!validatePhone(formData.mobile)) {
    //   toast.error('Your Phone Number is Wrong');
    //   return;
    // }

    try {
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit`, formData);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/projectregistration`, formData);
      toast.success(response.data.message || 'Form submitted successfully!');
      handleCancel(); // Clear the form after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  const handleCancel = () => {
    setSelectedProject('');
    setSubProject('');
    setSalutation('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setAddress('');
    setCountry('India'); // Reset country to India
    setState('');
    setCity('');
    setPincode('');
    setMobile('');
    setTelephone('');
    setMessage('');
    setHasCertification(false);
    setCertification('');
    // setCaptcha(null);
  };

  return (
    <div>
      <div className="main-form-div-bkg">
        <div className="Gem-main-div">
          <div className="Gem-btn">
            <a href="/" className="Gem-btn-link">
              Home <span className="separator"> &gt; </span> Register For GEM
            </a>
          </div>
        </div>
      

      <div className='main-form-div'>
        <div className="register-form">
          <form onSubmit={handleSubmit}>
            <div className="form-fields">
              <div className="form-fields-data">
                <label>Select Your Project *</label>
                <select value={selectedProject} onChange={handleProjectChange} required>
                  <option value="" disabled>Select a project</option>
                  {Object.keys(projectOptions).map((project, index) => (
                    <option key={index} value={project}>{project}</option>
                  ))}
                </select>
              </div>
         
              <div className="form-fields-data">
                <label>Select Your Sub Project *</label>
                <select value={subProject} onChange={(e) => setSubProject(e.target.value)} required>
                  <option value="" disabled>Select a sub-project</option>
                  {selectedProject ? (
                    projectOptions[selectedProject].map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))
                  ) : (
                    <option value="" disabled>Select a project to see sub-projects</option>
                  )}
                </select>
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data flex-container">
                <label>Salutations *</label>
                <div className="radio-container">
                  <div className="radio-item">
                    <input type="radio" name="salutation" value="Mr" onChange={(e) => setSalutation(e.target.value)} required />
                    <span>MR</span>
                  </div>
                  <div className="radio-item">
                    <input type="radio" name="salutation" value="Ms" onChange={(e) => setSalutation(e.target.value)} required />
                    <span>MS</span>
                  </div>
                  <div className="radio-item">
                    <input type="radio" name="salutation" value="Dr" onChange={(e) => setSalutation(e.target.value)} required />
                    <span>DR</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data">
                <label>First Name *</label>
                <input type="text" placeholder='First Name' value={firstName} onChange={handleFirstNameChange} required />
              </div>

              <div className="form-fields-data">
                <label>Last Name *</label>
                <input type="text" placeholder='Last Name' value={lastName} onChange={handleLastNameChange} required />
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data">
                <label>Email Id *</label>
                <input type="email" placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>

              <div className="form-fields-data">
                <label>Address Line *</label>
                <input type="text" placeholder='Address Line'  value={address} onChange={(e) => setAddress(e.target.value)} required />
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data">
                <label>Country *</label>
                <select value={country} onChange={handleCountryChange} required>
                  <option value="" disabled>Select your country</option>
                  {Object.keys(countryStateData).map((countryName, index) => (
                    <option key={index} value={countryName}>{countryName}</option>
                  ))}
                </select>
              </div>

              <div className="form-fields-data">
                <label>State *</label>
                <select value={state} onChange={(e) => setState(e.target.value)} required>
                  <option value="" disabled>Select your state</option>
                  {country === 'India' ? (
                    countryStateData[country].map((stateName, index) => (
                      <option key={index} value={stateName}>{stateName}</option>
                    ))
                  ) : (
                    <option value="" disabled>Select a country to see states</option>
                  )}
                </select>
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data">
                <label>City *</label>
                <input type="text" placeholder='City' value={city} onChange={handleCityChange} required />
              </div>

              <div className="form-fields-data">
                <label>Pincode *</label>
                <input type="text" placeholder='Pin Code' value={pincode} onChange={handlePincodeChange} required />
              </div>
            </div>

            <div className="form-fields">
              <div className="form-fields-data flex-container">
                <label>Mobile No. *</label>
                <input className='phone' type="text" placeholder='Phone Number' value={mobile} onChange={handleMobileChange} required /> 
              </div>

              <div className="form-fields-data">
                <label>Telephone No. (With STD Code)</label>
                <input type="tel" placeholder='Telephone Number' value={telephone} onChange={(e) => setTelephone(e.target.value)} />
              </div>
            </div>
           
            <div className="form-fields">
              <div className="form-fields-data"> 
                <label>Description</label>
                <textarea id='textArea' placeholder='Please describe your project' value={message} onChange={(e) => setMessage(e.target.value)} />
              </div>

              <div className="is-selected-div">
                <div className="form-fields-data flex-container" >
                  <label>Have you undertaken any other certification? *</label>
                  <div className="checkbox-container">
                    <div className="checkbox-item">
                      <input type="checkbox" checked={hasCertification} onChange={() => setHasCertification(!hasCertification)} />
                      <span>Yes</span>
                    </div>
                    <div className="checkbox-item">
                      <input type="checkbox" checked={!hasCertification} onChange={() => setHasCertification(!hasCertification)} />
                      <span>No</span>
                    </div>
                  </div>
                </div>

                {hasCertification && (
                  <div className="form-fields-data flex-container" id='is-selected-box'>
                    <label>Select Certification</label>
                    <select id='is-seleted-input' value={certification} onChange={(e) => setCertification(e.target.value)} required>
                      <option value="" disabled>Select certification</option>
                      {certificationOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
             
            </div>
            <div className="captcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  onChange={handleCaptchaChange}
                />
              </div>

            <div className="button-group-2">
              {/* <button type="submit" className="submit-btn" >Submit</button> */}
              <button type="submit" className="submit-btn" disabled={!captchaVerified}>Submit</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
    </div>
  );
};

export default Register2;
