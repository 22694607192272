// // GallerySlider.jsx
// import React from 'react';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './GallerySlider.css';
// import img1 from '../assets/GreenBuilding/greenbuilding1.jpg'
// import img2 from '../assets/GreenBuilding/greenbuilding2.jpg'
// import img3 from '../assets/GreenBuilding/greenbuilding3.jpg'
// import img4 from '../assets/GreenBuilding/Avani.jpg'
// import img5 from '../assets/GreenBuilding/Avani2.jpg'


// const GallerySlider = () => {
//     const cards = [
//         { id: 1, title: 'Suparshwanath, Pune', imgSrc: img1 },
//             // imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-8.webp' },
//         { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', 
//             imgSrc: img2 
//             // imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-9.webp' 
//             },
//         { id: 3, title: 'Alaya Apartment, Jaipur', 
//             imgSrc: img4
//             // imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-1.webp'
//          },
//         { id: 4, title: 'PPI System, Vatva, Ahmedabad', 
//             imgSrc: img3 
//             // imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-4.webp' 
//             },
//         { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', 
//             imgSrc: img5 
//             // imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-5.webp' 
//             },
//         { id: 6, title: '122, Wakad, Commercial Project, Pune', imgSrc: 'https://zen360.top/gem-green/wp-content/uploads/2023/11/project-6.webp' },
//     ];

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <div>
//         <div className="gallery-slider">
//             <Slider {...settings}>
//                 {cards.map((card) => (
//                     <div className="slider-card" key={card.id}>
//                         <img src={card.imgSrc} alt={card.title} />
//                         <h3>{card.title}</h3>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//         <button className="gallery-card-btn" id='gallery-card-btn-slider' >
//                 <Link to= '/gemGreen'>   Show All </Link> 
//                 </button>
// </div>
//     );
// };

// export default GallerySlider;



// new code 

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './GallerySlider.css';
import Modal from './Modal';  // Import the Modal component
import img1 from '../assets/GreenBuilding/Banners 1.jpg'
import img2 from '../assets/GreenBuilding/Banners 2.jpg'
import img3 from '../assets/GreenBuilding/Banners 3.jpg'
import img4 from '../assets/GreenBuilding/Banners 4.jpg'
import img5 from '../assets/GreenBuilding/Banners 5.jpg'
import img6 from '../assets/GreenBuilding/Banners 6.jpg'
import img7 from '../assets/GreenBuilding/Banners 7.jpg'
import img8 from '../assets/GreenBuilding/Banners 8.jpg'
import img9 from '../assets/GreenBuilding/Banners 9.jpg'
import img10 from '../assets/GreenBuilding/Banners 10.jpg'
import img11 from '../assets/GreenBuilding/Banners 11.jpg'
import img12 from '../assets/GreenBuilding/Banners 12.jpg'
import img13 from '../assets/GreenBuilding/Banners 13.jpg'
import img14 from '../assets/GreenBuilding/Banners 14.jpg'
import img15 from '../assets/GreenBuilding/Banners 15.jpg'
import img16 from '../assets/GreenBuilding/Banners 16.jpg'
import img17 from '../assets/GreenBuilding/Banners 17.jpg'
import img18 from '../assets/GreenBuilding/Banners 18.jpg'
import img19 from '../assets/GreenBuilding/Banners 19.jpg'
import img20 from '../assets/GreenBuilding/Banners 20.jpg'
import img21 from '../assets/GreenBuilding/Banners 21.jpg'
import img22 from '../assets/GreenBuilding/Banners 22.jpg'
import img23 from '../assets/GreenBuilding/Banners 23.jpg'
import img24 from '../assets/GreenBuilding/Banners 24.jpg'
import img25 from '../assets/GreenBuilding/Banners 25.jpg'
import img26 from '../assets/GreenBuilding/Banners 26.jpg'
import img27 from '../assets/GreenBuilding/Banners 27.jpg'
import img28 from '../assets/GreenBuilding/Banners 28.jpg'
import img29 from '../assets/GreenBuilding/Banners 29.jpg'
import img30 from '../assets/GreenBuilding/Banners 30.jpg'
import img31 from '../assets/GreenBuilding/Banners 31.jpg'
import img32 from '../assets/GreenBuilding/Banners 32.jpg'
import img33 from '../assets/GreenBuilding/Banners 33.jpg'
import img34 from '../assets/GreenBuilding/Banners 34.jpg'
import img35 from '../assets/GreenBuilding/Banners 35.jpg'


const GallerySlider = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const cards = [
        { id: 1, title: 'Suparshwanath, Pune', imgSrc: img1 },
        { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', imgSrc: img2 },
        { id: 3, title: 'Alaya Apartment, Jaipur', imgSrc: img3 },
        { id: 4, title: 'PPI System, Vatva, Ahmedabad', imgSrc: img4 },
        { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img5 },
        { id: 6, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img6 },
        { id: 7, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img7 },
        { id: 8, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img8 },
        { id: 9, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img9 },
        { id: 10, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img10 },
        { id: 11, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img11 },
        { id: 12, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img12 },
        { id: 13, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img13 },
        { id: 14, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img14 },
        { id: 15, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img15 },
        { id: 16, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img16 },
        { id: 17, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img17 },
        { id: 18, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img18 },
        { id: 19, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img19 },
        { id: 20, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img20 },
        { id: 21, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img21 },
        { id: 22, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img22 },
        { id: 23, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img23 },
        { id: 24, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img24 },
        { id: 25, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img25 },
        { id: 26, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img26 },
        { id: 27, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img27 },
        { id: 28, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img28 },
        { id: 29, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img29 },
        { id: 30, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img30 },
        { id: 31, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img31 },
        { id: 32, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img32 },
        { id: 33, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img33 },
        { id: 34, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img34 },
        { id: 35, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img35 },
        
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const openModal = (imgSrc) => {
        setSelectedImage(imgSrc);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div>
            <div className="gallery-slider">
                <Slider {...settings}>
                    {cards.map((card) => (
                        <div className="slider-card" key={card.id} onClick={() => openModal(card.imgSrc)}>
                            <img src={card.imgSrc} alt={card.title} />
                            <h3>{card.title}</h3>
                        </div>
                    ))}
                </Slider>
            </div>
            <button className="gallery-card-btn" id='gallery-card-btn-slider'>
                <Link to='/gemGreen'>Show All</Link>
            </button>

            {/* Modal will only show if `isModalOpen` is true */}
            {isModalOpen && <Modal imgSrc={selectedImage} altText="Selected Image" closeModal={closeModal} className='Gallery-model-logic' />}
        </div>
    );
};

export default GallerySlider;
