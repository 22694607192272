import React from 'react';
import './PrivacyPolicy.css'
const PrivacyPolicy = () => {
  return (
    <div>

<div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> Privacy Policy
          </a>
        </div>
      </div>


    <div className="privacy-policy-page">
        <div className="privacy_upperChild-Div">
      <header className="privacy-heading">
        <h2 className='privacy-policyHeading'>ASSOCHAM Privacy Policy</h2>
      </header>

      <section className="terms-of-service">
      {/* <h4 className="privacy-subHeading">Applicability:</h4> */}
        <p className='Privacy-para'>
            
        This Privacy Policy applies to the Assocham and the Websites http://green-assocham.com/("Website"). In this Privacy Policy the terms "we", "us" and "our" are a reference to the Assocham.
        </p>
        <p className='Privacy-para'>
            
        Your privacy is important for Assocham & we respects personal data and is committed to fully implementing and complying with the data protection principles and all relevant provisions of the Personal Data (Privacy).
        </p>
        <h4>About the Assocham and Privacy Policy</h4>
        <p className='Privacy-para'>
        We collect personal information like your Internet Protocol address, your browser type, your operating system, and the pages you view on the Site, the pages you view immediately before and after you access the Site and the search terms you enter on the Site, etc, when you use the site including when you enter project information into the Assocham software. 
        </p>
        <p className='Privacy-para'>
        We want you to understand the terms and conditions surrounding the capture and use of any information we gather. This Privacy Policy discloses what information we gather, how we use this information and how you can correct or change it. Changes to this document are done periodically.
        </p>
        <h4>Use of Assocham's logo</h4>
        <p className='Privacy-para'>
        Use of the Assocham's logo without permission or prior written approval from the Assocham is prohibited. Please contact the Counselor, via email    --------------    if you need further information.
        </p>
        <h3>Information Collection by Assocham’s Website</h3>
        <p className='Privacy-para'>
        We may gather two types of information from you:
        <ul className="privacy-list-items">
            <li className="privacyList">Personal information, provided by you for the services we offer; and</li>
            <li className="privacyList">Information generated by our system that tracks traffic to our Website.</li>
        </ul>
        </p>
        <h3>Personal information we would collect includes but not limited to:</h3>
        <p className='Privacy-para'>
        We may gather two types of information from you:
        <ul className="privacy-list-items">
            <li className="privacyList">Individual's name;</li>
            <li className="privacyList">Organization name;</li>
            <li className="privacyList">Designation;</li>
            <li className="privacyList">Department;</li>
            <li className="privacyList">Email address(s);</li>
            <li className="privacyList">Personal and/ or Organization and/ or correspondence addresses;</li>
            <li className="privacyList">Mobile and Telephone numbers;</li>
        </ul>
        </p>
      </section>

      <section className="privacy-policy">
        <h3>HOW DO WE USE THIS INFORMATION?</h3>
        <p className='Privacy-para'>
        We use the collected personal information to communicate and deliver information including but not limited to:
        <ul className="privacy-list-items">
            <li className="privacyList">Organization updates of Assocham;</li>
            <li className="privacyList">Membership administration and communication;</li>
            <li className="privacyList">Project certification administration and communication;</li>
            <li className="privacyList">Assocham Accredited Professional Examination administration and communication;</li>
            <li className="privacyList">Assocham organized, co-organized and supported events and functions;</li>
        </ul>
        <p className="privacy-para">To ensure that Assocham handles personal data in a professional manner, we maintain the following privacy principles, and under the circumstances stated in clause 8. Below:</p>
        <ul className="privacy-list-items-child">
            <li className="privacyList-child">Assocham may pass your personal information to third party assessors for certification, examination vendors for Assocham or other parties and appointed service providers directly involved in the activities with Assocham.</li>
            <li className="privacyList-child">Assocham may be required to disclose your personal information to governmental bodies or regulators per statutory requirements, but we will only do so under strict authority.</li>
            <li className="privacyList-child">Assocham strives to maintain an accurate and up-to-date record of your personal information. Assocham adheres to strict security standards and procedures to protect your personal information from any unauthorized access by anyone, including our staff.</li>
            
        </ul>
        <p className="privacy-para">All staff of Assocham and all third parties with permitted access to your information are required to respect your confidentially and observe the privacy principles. Assocham values professionalism and integrity in our work. With our commitment and devotion to observe these principles, we assure your confidentiality in full.</p>
        <p className="privacy-para">You have the option to opt out from receiving any future marketing material at any time by contacting the Assocham.</p>
        </p>

        <h3>Voluntary Submission of Information</h3>
        <p className='Privacy-para'>
        You may use the Website without disclosing personally identifiable information, and we will not obtain such information about you unless you choose to submit it to us. Any information you submit will only be used internally. Submission of information authorizes such internal use by us. In particular, please note that submission of an email authorizes us to contact you via email.
        </p>
        <h3>Individual Profile</h3>
        <p className='Privacy-para'>
        This is your personal account for your own personal Assocham related activities. Please do not share your login information. Once you log onto the Website you can keep your personal and contact information up to date if you move, change your email address or information. You agree to accept responsibility for all the activities that occur under your account or password.
        </p>
        <h3>Holding, correction and updating of personal information</h3>
        <p className='Privacy-para'>
        You have the right to access to, and to correct, the personal information we hold. If you find that information we hold about you is incorrect, please correct or update the information by signing into your account, or contact us.
        </p>
        <h3>1. Email</h3>
        <p className='Privacy-para'>
        Our Website provides opportunities for Users to email information to other individuals. This functionality is merely for sharing of information and does not incur any direct selling or commercial purpose.
        </p>

        
        <h3>2. Disclosure of Information</h3>
        <p className='Privacy-para'>
        We will not rent, sell or exchange information about you to any third parties (other than appointed service providers) except what you have provided prior consent to disclose, or except what you would expect to be disclosed, or except what we have indicated we may disclose; or except what we are legally obliged to disclose.
        </p>

        <h3>3. Online Payment Information</h3>
        <p className='Privacy-para'>
        If you wish to avail our services or purchase products from the Website, you will be required to submit your credit card/ Debit Card/ Net Banking information to the Website. This information will be sent directly to our third party payment service that is safe & secure.
        </p>

        <h3>4. Protection and Security of Personal Information</h3>
        <p className='Privacy-para'>
        We take responsible steps to protect all personal information collected or held about our Users and/ or Members from being misused and lost, and from unauthorized access, modification and disclosure.
        </p>

        <h3>5. Other Sites</h3>
        <p className='Privacy-para'>
        Our Website may contain links to third party websites, for your convenience and information. When you access a third party website, please understand that we are not responsible for the privacy practices of the corresponding website. We suggest that you review the privacy policies of each website you visit.
        </p>

        <h3>6. Cookies</h3>
        <p className='Privacy-para'>
        We may use a cookie file containing information that can identify the computer you are working from. You can choose to refuse cookies by turning them off in your browser and/or deleting them from your hard drive. You will not be able to maintain a logged in session if cookies are not used.
        </p>

        <h3>7. Changes to this Privacy Policy</h3>
        <p className='Privacy-para'>
        We reserve the right to change this Privacy Policy at any time. Not all changes to our Privacy Policy will require the consent of our Users. We will notify our Users of any change to our information handling policy that requires your consent before implementing.
        </p>

        <h3>8. Failure to provide information</h3>
        <p className='Privacy-para'>
        Failure to provide the necessary personal information when requested may result in certain services not being available to you.
        </p>

        <h3>9. Coverage and Complaints</h3>
        <p className='Privacy-para'>
        The above covers the privacy policy of the Assocham, which is part of Confederation of Indian Industry (CII). If you have any complaint on the basis that you observe your privacy has been breached because of our failure in complying with this privacy policy, you could contact us by the following methods:
        </p>



       <h3 className="privacyLine-break">----------------------------------------------------------</h3>
       <h3 className="privacyLine-break">----------------------------------------------------------</h3>
       <h3 className="privacyLine-break">----------------------------------------------------------</h3>
        
       
       <h3>DISCLAIMER</h3>
        <p className='Privacy-para'>
        The material contained in this website has been prepared by the Assocham, on the basis of the most current information available to it. Assocham believes, to the best of its knowledge, that all information given is reliable, but makes no guarantee of its accuracy.
        </p>
        <p className='Privacy-para'>
        Assocham and the organizations and individuals represented in this website are not responsible for any errors or omissions contained in this website, and reserve the right to make changes without notice. The said parties take no responsibility in respect of any use or reference to this website or the information contained in it, and are not liable in any event, for any damages whatsoever arising out of or related to this website or the information it contains.
        </p>
            
            
         
            
        


      </section>
      </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
