

// // new code 

// import React, { useState, useEffect } from "react";
// import India from "@svg-maps/india";
// import { SVGMap } from "react-svg-map";
// import "react-svg-map/lib/index.css";
// import './NewGeoEcoSystemcolor.css';
// import './NewGeoEcoSystem.css';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

// // Color mapping for each state
// const stateColors = {
//   "Uttar Pradesh": "#FF6347", // Tomato color
//   "Maharashtra": "#32CD32",   // Lime Green color
//   "Karnataka": "#1E90FF",     // Dodger Blue
//   "Gujarat": "#FFD700",       // Gold
//   "Bihar": "#00FA9A",         // Medium Spring Green
//   "West Bengal": "#8A2BE2",   // Blue Violet
//   "Rajasthan": "#FF4500",     // Orange Red
//   "Kerala": "#FF69B4",        // Hot Pink
//   "Andhra Pradesh": "#6A5ACD", // Slate Blue
//   "Haryana": "#DC143C",       // Crimson
//   "Punjab": "#008B8B",        // Dark Cyan
//   "Uttarakhand": "#A52A2A",   // Brown
//   "Himachal Pradesh": "#C71585", // Medium Violet Red
//   "Jammu and Kashmir": "#8B0000", // Dark Red
//   "Arunachal Pradesh": "#7FFF00", // Chartreuse
//   "Nagaland": "#800000",      // Maroon
//   "Madhya Pradesh": "#FF6347", // Tomato
//   "Chhattisgarh": "#FF6347",  // Tomato
//   "Odisha": "#FF6347",        // Tomato
//   "Assam": "#7FFF00",         // Chartreuse
//   "Jharkhand": "#32CD32",     // Lime Green
//   "Meghalaya": "#00FA9A",     // Medium Spring Green
//   "Manipur": "#7FFF00",       // Chartreuse
//   "Mizoram": "#1E90FF",       // Dodger Blue
//   "Sikkim": "#32CD32",        // Lime Green
//   "Tripura": "#1E90FF",       // Dodger Blue
//   "Goa": "#FFD700",           // Gold
//   "Delhi": "#DC143C",         // Crimson
//   "Puducherry": "#1E90FF",    // Dodger Blue
//   "Andaman and Nicobar Islands": "#4682B4", // Steel Blue
//   "Lakshadweep": "#00FA9A",   // Medium Spring Green
//   "Daman and Diu": "#1E90FF", // Dodger Blue
//   "Dadra and Nagar Haveli": "#FFD700", // Gold
//   "Chandigarh": "#32CD32",    // Lime Green
//   "Ladakh": "#8B0000",        // Dark Red
// };

// // Example data for selected state details
// // Example data for selected state details
// const stateData = {
//   "Uttar Pradesh": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANUPAM-MITTAL.webp", 
//     chairManName: "Ar Anupam Mittal", 
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: 'Uttar Pardesh',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: "Ar. Anurag Bajpai" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Uttar Pardesh',
//   },
//   "Maharashtra": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/user-1.webp", 
//     chairManName: "Ar. Vilas Avachat", 
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: "Maharashtra",
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Roshni-Udyavar-Yehuda.webp", 
//     coChairManName: "Ar. Roshni Udyavar Yehuda",
//     post2: 'Co-Chair' ,
//     organization: " ",
//     StateName: "Maharashtra",
//   },
//   "Chhattisgarh": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/NAVIN-SHARMA.webp", 
//     chairManName: "Ar. Navin Sharma",
//     post1: 'Chair' , 
//     organization: " ",
//     StateName1: 'Chhattisgarh',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Raj-Prajapati.webp", 
//     coChairManName: " Ar. Raj Prajapati" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Chhattisgarh',
//   },
//   "Gujarat": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ANAND-TATU.webp", 
//     chairManName: " Ar. Anand Tatu", 
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: 'Gujarat',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Lokendra-Balasaria.webp", 
//     coChairManName: " Ar. Lokendra Balasariya" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Gujarat',
//   },
//   "Haryana": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/PUNIT-SETHI.webp", 
//     chairManName: " Ar. Punit Sethi",
//     post1: 'Chair' , 
//     organization: " ",
//     StateName1: 'Haryana',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " Mr. Sanjay Varshney" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Haryana',
//   },
//   "Jharkhand": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ARUN-KUMAR.webp", 
//     chairManName: "Ar. Arun Kumar", 
//     post1: 'Chair' ,
//     organization1: " ",
//     StateName: 'Jharkhand',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " ",
//     post2: 'Co-Chair' ,
//     organization: " ",
//     StateName: 'Jharkhand',
//   },
//   "Karnataka": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/LEENA-KUMAR.webp", 
//     chairManName: "Ar. Leena Kumar", 
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: 'Karnataka',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Bindi-Saolapurkar.webp", 
//     coChairManName: " Ar. Bindi Saolapurkar",
//     post2: 'Co-Chair' ,
//     organization: " ",
//     StateName: 'Karnataka',
//   },
//   "Kerala": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SUDHIR-BALAKRISHNAN.webp", 
//     chairManName: "Ar. Sudhir Balakrishnan",
//     post1: 'Chair' , 
//     organization: " ",
//     StateName1: 'Kerala',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
//     coChairManName: " Mr. Shreeganesh V Nair" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Kerala',
//   },
//   "Odisha": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SOUMYENDU-S-RAY.webp", 
//     chairManName: "Ar. (Prof.) S. S. Ray ", 
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: 'Punjab',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webp", 
//     coChairManName: " Prof. Sangram Mohanty" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Odisha',
//   },
//   "Punjab": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/SURINDER-BAHGA-1.webp", 
//     chairManName: "Ar. Surinder Bahga ",
//     post1: 'Chair' ,
//     organization: " ", 
//     StateName1: 'Punjab',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Sangram-Mohanty.webp", 
//     coChairManName: " Ar. Balkar Singh",
//     post2: 'Co-Chair',
//     organization: " " ,
//     StateName: 'Punjab',
//   },
//   "Rajasthan": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/TUSHAR-SOGANI.webp", 
//     chairManName: " Ar. Tushar Sogani ",
//     post1: 'Chair' ,
//     organization: " ", 
//     StateName1: 'Rajasthan',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Gaurav-Agrawal.webp", 
//     coChairManName: " Ar. Gaurav Agrawal" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'Rajasthan',
//   },
//   "West Bengal": { 
//     chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/DEBATOSH-SAHU.webp", 
//     chairManName: "Ar. Debatosh Sahu",
//     post1: 'Chair' ,
//     organization: " ",
//     StateName1: 'West Bengal',
//     coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/12/Abin-Chaudhuri.webp", 
//     coChairManName: "  Ar. Abin Chaudhuri" ,
//     post2: 'Co-Chair',
//     organization: " ",
//     StateName: 'West Bengal',
//   },
//   // Add other states here...
// };

// const defaultData = {
//   defaultSecretary: {
//     name: "Deepak Sood",
//     post1: "Secretary General",
//     organization: "ASSOCHAM",
//     img: "https://zen360.top/gem-green/wp-content/uploads/2023/11/Deepak-Sood.webp",
//     StateName: " ",
//   },
//   defaultCoChair: {
//     name: "Pankaj R Dharkar",
//     post2: "National Chair",
//     organization: "ASSOCHAM",
//     img: "https://zen360.top/gem-green/wp-content/uploads/2023/11/Pankaj-Dharkar-480x229.webp",
//     StateName: " "
//   },
// };

// function NewGeoEcoSystemTest() {
//   const [selectedState, setSelectedState] = useState(null);
//   const [hoverState, setHoverState] = useState(null);
//   const [isHovered, setIsHovered] = useState(false); // Track hover state
//   const [stateCoordinates, setStateCoordinates] = useState({});

//   useEffect(() => {
//     const coords = {};
//     Object.keys(stateData).forEach((stateName) => {
//       // Assuming the state names exist in your map and corresponding state data is available
//       const rect = document.querySelector(`.state-${stateName.replace(/\s+/g, '-').toLowerCase()}`);
//       if (rect) {
//         const boundingRect = rect.getBoundingClientRect();
//         coords[stateName] = {
//           x: boundingRect.left + boundingRect.width / 2.6,
//           y: boundingRect.top + boundingRect.height / 4,
//         };
//       }
//     });
//     setStateCoordinates(coords);
//   }, [stateData]);

//   const onLocationHover = (event) => {
//     const stateName = event.target.getAttribute("name");
//     const stateInfo = stateData[stateName] || defaultData;

//     if (event.target instanceof SVGElement) {
//       const rect = event.target.getBoundingClientRect();
//       setStateCoordinates((prevCoords) => ({
//         ...prevCoords,
//         [stateName]: {
//           x: rect.left + rect.width / 2.6,
//           y: rect.top + rect.height / 4,
//         },
//       }));

//       setHoverState({
//         name: stateName,
//         chairMan: stateInfo.chairMan || defaultData.defaultSecretary.img,
//         chairManName: stateInfo.chairManName || defaultData.defaultSecretary.name,
//         coChairMan: stateInfo.coChairMan || defaultData.defaultCoChair.img,
//         coChairManName: stateInfo.coChairManName || defaultData.defaultCoChair.name,
//         Post1: stateInfo.post1 || defaultData.defaultSecretary.post1,
//         Post2: stateInfo.post2 || defaultData.defaultCoChair.post2,
//         organization1: stateInfo.organization || defaultData.defaultSecretary.organization,
//         StateName: stateInfo.StateName || defaultData.defaultSecretary.StateName,
//         StateName1: stateInfo.StateName1 || defaultData.defaultCoChair.StateName,
//       });

//       setIsHovered(true);
//     }
//   };

//   const onLocationMouseOut = () => {
//     setIsHovered(false);
//     setHoverState(null);
//   };

//   const onLocationClick = (event) => {
//     const stateName = event.target.getAttribute("name");
//     if (stateData[stateName]) {
//       setSelectedState({ name: stateName, ...stateData[stateName] });
//     } else {
//       setSelectedState(null);
//     }
//   };

//   const locationClassName = (location) => {
//     const stateName = location.name;
//     if (stateColors[stateName]) {
//       return `state-${stateName.replace(/\s+/g, '-').toLowerCase()}`;
//     }
//     return "state-default";
//   };

//   return (
//     <div>
//       <div className="GemChapter-bkg">
//         <div className="about-main-div">
//           <div className="about-btn">
//             <a href="#" className="about-btn-link">
//               Home <span className="separator"> &gt; </span> GEM Chapters
//             </a>
//           </div>
//         </div>
//         <div className="main-div-of-map">
//           <div className="map-main-div">
//             <div className="btn-div-map">
//               {/* Show default data if no state is selected and not hovering */}
//               {!selectedState && !isHovered && (
//                 <div className="card-map-main">
//                   <div className="map-detail-card">
//                     <img
//                       src={defaultData.defaultSecretary.img}
//                       alt="Chair Man"
//                       className="map-detail-img"
//                     />
//                     <p className="ecoList">{defaultData.defaultSecretary.name}</p>
//                     <p className="ecoList">{defaultData.defaultSecretary.post1}</p>
//                     <p className="ecoList">{defaultData.defaultSecretary.organization}</p>
//                   </div>
//                   <div className="map-detail-card">
//                     <img
//                       src={defaultData.defaultCoChair.img}
//                       alt="Co-Chair Man"
//                       className="map-detail-img"
//                     />
//                     <p className="ecoList">{defaultData.defaultCoChair.name}</p>
//                     <p className="ecoList">{defaultData.defaultCoChair.post2}</p>
//                     <p className="ecoList">{defaultData.defaultCoChair.organization}</p>
//                   </div>
//                 </div>
//               )}

//               {/* Show selected state details */}
//               {selectedState && (
//                 <div className="map-detail-container">
//                   <div className="map-cardHeading">
//                     {selectedState && <h3 className="state-name">{selectedState.name}</h3>}
//                   </div>
//                   <div className="card-map-main">
//                     <div className="map-detail-card">
//                       <img src={selectedState.chairMan} alt="Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{selectedState.chairManName}</p>
//                       <p className="ecoList">{selectedState.post1}</p>
//                     </div>

//                     <div className="map-detail-card">
//                       <img src={selectedState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{selectedState.coChairManName}</p>
//                       <p className="ecoList">{selectedState.post2}</p>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>

//             <div className="map-container">
//               <div className="map-detail-container" style={{ display: "flex" }}>
//                 {hoverState && !selectedState && (
//                   <div className="card-map-main">
//                     <div className="map-detail-card">
//                       <img src={hoverState.chairMan} alt="Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{hoverState.chairManName}</p>
//                       <p className="ecoList">{hoverState.Post1}</p>
//                       <p className="ecoList">{hoverState.organization1}</p>
//                       <p className="ecoList">{hoverState.StateName}</p>
//                     </div>

//                     <div className="map-detail-card">
//                       <img src={hoverState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
//                       <p className="ecoList">{hoverState.coChairManName}</p>
//                       <p className="ecoList">{hoverState.Post2}</p>
//                       <p className="ecoList">{hoverState.organization1}</p>
//                       <p className="ecoList">{hoverState.StateName}</p>
//                     </div>
//                   </div>
//                 )}
//               </div>
//               <SVGMap
//                 map={India}
//                 onLocationClick={onLocationClick}
//                 onLocationMouseOver={onLocationHover}
//                 onLocationMouseOut={onLocationMouseOut}
//                 locationClassName={locationClassName}
//               />
//               {Object.keys(stateData).map((stateName) => {
//                 const coordinates = stateCoordinates[stateName];
//                 if (coordinates) {
//                   return (
//                     <div
//                       key={stateName}
//                       style={{
//                         position: "absolute",
//                         left: coordinates.x,
//                         top: coordinates.y,
//                         fontSize: "15px",
//                         color: "red",
//                       }}
//                     >
//                       <div className="State-location-flexBox">
//                         <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
//                         <p className="stateName">{stateName}</p>
//                       </div>
//                     </div>
//                   );
//                 }
//                 return null;
//               })}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default NewGeoEcoSystemTest;



// new code 

import React, { useState, useEffect } from "react";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import './NewGeoEcoSystemcolor.css';
import './NewGeoEcoSystem.css';
import DefaultChair from '../assets/GemChapterChair/Manish Singhal Secretary General ASSOCHAM.jpg'
// import DefaultChair from '../assets/GemChapterChair/DeepakSood.png'
import DefaultCoChair from '../assets/GemChapterChair/Pankaj Dharkar.png'
import UpChair from '../assets/GemChapterChair/ANUPAM MITTAL.png'
import PNChair from '../assets/GemChapterChair/SURINDER BAHGA.png'
import PNCoChair from '../assets/GemChapterChair/Dr. Balkar Singh.png'
import HRChair from '../assets/GemChapterChair/PUNIT SETHI.png'
import RJChair from '../assets/GemChapterChair/TUSHAR SOGANI.png'
import RJCoChair from '../assets/GemChapterChair/Ar. Gaurav Agrawal.png'
import MHChair from '../assets/GemChapterChair/VILAS AVACHAT VASANT.png'
import MHCochair from '../assets/GemChapterChair/Ar. Roshni Udyavar Yehuda.png'
import GJChair from '../assets/GemChapterChair/ANAND TATU.png'
import GJCoChair from '../assets/GemChapterChair/Ar. Lokendra Balasaria.png'
import KTChair from '../assets/GemChapterChair/Leena Kumar.png'
import KTCoChair from '../assets/GemChapterChair/Ar. Bindi Saolapurkar.png'
import KLChair from '../assets/GemChapterChair/SUDHIR BALAKRISHNAN.png'
import CHHChair from '../assets/GemChapterChair/NAVIN SHARMA.png'
import CHHCoChair from '../assets/GemChapterChair/Ar. Raj Prajapati.png'
import ODChair from '../assets/GemChapterChair/SOUMYENDU S RAY.png'
import ODCoChair from '../assets/GemChapterChair/Prof. Sangram Mohanty.png'
import WBChair from '../assets/GemChapterChair/DEBATOSH SAHU.png'
import WBCoChair from '../assets/GemChapterChair/Ar. Abin Chaudhuri.png'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

// Color mapping for each state
const stateColors = {
  "Uttar Pradesh": "#FF6347", // Tomato color
  "Maharashtra": "#32CD32",   // Lime Green color
  "Karnataka": "#1E90FF",     // Dodger Blue
  "Gujarat": "#FFD700",       // Gold
  "Bihar": "#00FA9A",         // Medium Spring Green
  "West Bengal": "#8A2BE2",   // Blue Violet
  "Rajasthan": "#FF4500",     // Orange Red
  "Kerala": "#FF69B4",        // Hot Pink
  "Andhra Pradesh": "#6A5ACD", // Slate Blue
  "Haryana": "#DC143C",       // Crimson
  "Punjab": "#008B8B",        // Dark Cyan
  "Uttarakhand": "#A52A2A",   // Brown
  "Himachal Pradesh": "#C71585", // Medium Violet Red
  "Jammu and Kashmir": "#8B0000", // Dark Red
  "Arunachal Pradesh": "#7FFF00", // Chartreuse
  "Nagaland": "#800000",      // Maroon
  "Madhya Pradesh": "#FF6347", // Tomato
  "Chhattisgarh": "#FF6347",  // Tomato
  "Odisha": "#FF6347",        // Tomato
  "Assam": "#7FFF00",         // Chartreuse
  "Jharkhand": "#32CD32",     // Lime Green
  "Meghalaya": "#00FA9A",     // Medium Spring Green
  "Manipur": "#7FFF00",       // Chartreuse
  "Mizoram": "#1E90FF",       // Dodger Blue
  "Sikkim": "#32CD32",        // Lime Green
  "Tripura": "#1E90FF",       // Dodger Blue
  "Goa": "#FFD700",           // Gold
  "Delhi": "#DC143C",         // Crimson
  "Puducherry": "#1E90FF",    // Dodger Blue
  "Andaman and Nicobar Islands": "#4682B4", // Steel Blue
  "Lakshadweep": "#00FA9A",   // Medium Spring Green
  "Daman and Diu": "#1E90FF", // Dodger Blue
  "Dadra and Nagar Haveli": "#FFD700", // Gold
  "Chandigarh": "#32CD32",    // Lime Green
  "Ladakh": "#8B0000",        // Dark Red
};

// Example data for selected state details
// Example data for selected state details
const stateData = {
  "Uttar Pradesh": { 
    chairMan: UpChair, 
    chairManName: "Ar Anupam Mittal", 
    post1: 'Chair' ,
    organization: " ",
    StateName1: 'Uttar Pardesh',
    coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
    coChairManName: "Ar. Anurag Bajpai" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Uttar Pardesh',
  },
  "Maharashtra": { 
    chairMan: MHChair, 
    chairManName: "Ar. Vilas Avachat", 
    post1: 'Chair' ,
    organization: " ",
    StateName1: "Maharashtra",
    coChairMan: MHCochair, 
    coChairManName: "Ar. Roshni Udyavar Yehuda",
    post2: 'Co-Chair' ,
    organization: " ",
    StateName: "Maharashtra",
  },
  "Chhattisgarh": { 
    chairMan: CHHChair, 
    chairManName: "Ar. Navin Sharma",
    post1: 'Chair' , 
    organization: " ",
    StateName1: 'Chhattisgarh',
    coChairMan: CHHCoChair, 
    coChairManName: " Ar. Raj Prajapati" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Chhattisgarh',
  },
  "Gujarat": { 
    chairMan: GJChair, 
    chairManName: " Ar. Anand Tatu", 
    post1: 'Chair' ,
    organization: " ",
    StateName1: 'Gujarat',
    coChairMan: GJCoChair, 
    coChairManName: " Ar. Lokendra Balasariya" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Gujarat',
  },
  "Haryana": { 
    chairMan: HRChair, 
    chairManName: " Ar. Punit Sethi",
    post1: 'Chair' , 
    organization: " ",
    StateName1: 'Haryana',
    coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
    coChairManName: " Mr. Sanjay Varshney" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Haryana',
  },
  "Jharkhand": { 
    chairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/11/ARUN-KUMAR.webp", 
    chairManName: "Ar. Arun Kumar", 
    post1: 'Chair' ,
    organization1: " ",
    StateName: 'Jharkhand',
    coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
    coChairManName: " ",
    post2: 'Co-Chair' ,
    organization: " ",
    StateName: 'Jharkhand',
  },
  "Karnataka": { 
    chairMan: KTChair, 
    chairManName: "Ar. Leena Kumar", 
    post1: 'Chair' ,
    organization: " ",
    StateName1: 'Karnataka',
    coChairMan: KTCoChair, 
    coChairManName: " Ar. Bindi Saolapurkar",
    post2: 'Co-Chair' ,
    organization: " ",
    StateName: 'Karnataka',
  },
  "Kerala": { 
    chairMan: KLChair, 
    chairManName: "Ar. Sudhir Balakrishnan",
    post1: 'Chair' , 
    organization: " ",
    StateName1: 'Kerala',
    coChairMan: "https://zen360.top/gem-green/wp-content/uploads/2023/10/unknown-person.webp", 
    coChairManName: " Mr. Shreeganesh V Nair" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Kerala',
  },
  "Odisha": { 
    chairMan: ODChair, 
    chairManName: "Ar. (Prof.) S. S. Ray ", 
    post1: 'Chair' ,
    organization: " ",
    StateName1: 'Punjab',
    coChairMan: ODCoChair, 
    coChairManName: " Prof. Sangram Mohanty" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Odisha',
  },
  "Punjab": { 
    chairMan: PNChair, 
    chairManName: "Ar. Surinder Bahga ",
    post1: 'Chair' ,
    organization: " ", 
    StateName1: 'Punjab',
    coChairMan: PNCoChair, 
    coChairManName: " Ar. Balkar Singh",
    post2: 'Co-Chair',
    organization: " " ,
    StateName: 'Punjab',
  },
  "Rajasthan": { 
    chairMan: RJChair, 
    chairManName: " Ar. Tushar Sogani ",
    post1: 'Chair' ,
    organization: " ", 
    StateName1: 'Rajasthan',
    coChairMan: RJCoChair, 
    coChairManName: " Ar. Gaurav Agrawal" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'Rajasthan',
  },
  "West Bengal": { 
    chairMan: WBChair, 
    chairManName: "Ar. Debatosh Sahu",
    post1: 'Chair' ,
    organization: " ",
    StateName1: 'West Bengal',
    coChairMan: WBCoChair, 
    coChairManName: "  Ar. Abin Chaudhuri" ,
    post2: 'Co-Chair',
    organization: " ",
    StateName: 'West Bengal',
  },
  // Add other states here...
};

const defaultData = {
  defaultSecretary: {
    name: "Manish Singhal ",
    post1: "Secretary General",
    organization: "ASSOCHAM",
    img: DefaultChair,
    StateName: " ",
  },
  defaultCoChair: {
    name: "Pankaj R Dharkar",
    post2: "National Chair",
    organization: "ASSOCHAM",
    img: DefaultCoChair,
    StateName: " "
  },
};

function NewGeoEcoSystemTest() {
  const [selectedState, setSelectedState] = useState(null);
  const [hoverState, setHoverState] = useState(null);
  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const [stateCoordinates, setStateCoordinates] = useState({});

  useEffect(() => {
    const coords = {};
    Object.keys(stateData).forEach((stateName) => {
      // Assuming the state names exist in your map and corresponding state data is available
      const rect = document.querySelector(`.state-${stateName.replace(/\s+/g, '-').toLowerCase()}`);
      if (rect) {
        const boundingRect = rect.getBoundingClientRect();
        coords[stateName] = {
          x: boundingRect.left + boundingRect.width / 2.6,
          y: boundingRect.top + boundingRect.height / 4,
        };
      }
    });
    setStateCoordinates(coords);
  }, [stateData]);

  const onLocationHover = (event) => {
    const stateName = event.target.getAttribute("name");
    const stateInfo = stateData[stateName] || defaultData;

    if (event.target instanceof SVGElement) {
      const rect = event.target.getBoundingClientRect();
      setStateCoordinates((prevCoords) => ({
        ...prevCoords,
        [stateName]: {
          x: rect.left + rect.width / 2.6,
          y: rect.top + rect.height / 4,
        },
      }));

      setHoverState({
        name: stateName,
        chairMan: stateInfo.chairMan || defaultData.defaultSecretary.img,
        chairManName: stateInfo.chairManName || defaultData.defaultSecretary.name,
        coChairMan: stateInfo.coChairMan || defaultData.defaultCoChair.img,
        coChairManName: stateInfo.coChairManName || defaultData.defaultCoChair.name,
        Post1: stateInfo.post1 || defaultData.defaultSecretary.post1,
        Post2: stateInfo.post2 || defaultData.defaultCoChair.post2,
        organization1: stateInfo.organization || defaultData.defaultSecretary.organization,
        StateName: stateInfo.StateName || defaultData.defaultSecretary.StateName,
        StateName1: stateInfo.StateName1 || defaultData.defaultCoChair.StateName,
      });

      setIsHovered(true);
    }
  };

  const onLocationMouseOut = () => {
    setIsHovered(false);
    setHoverState(null);
  };

  const onLocationClick = (event) => {
    const stateName = event.target.getAttribute("name");
    if (stateData[stateName]) {
      setSelectedState({ name: stateName, ...stateData[stateName] });
    } else {
      setSelectedState(null);
    }
  };

  const locationClassName = (location) => {
    const stateName = location.name;
    if (stateColors[stateName]) {
      return `state-${stateName.replace(/\s+/g, '-').toLowerCase()}`;
    }
    return "state-default";
  };

  return (
    <div>
      <div className="GemChapter-bkg">
        <div className="about-main-div">
          <div className="about-btn">
            <a href="#" className="about-btn-link">
              Home <span className="separator"> &gt; </span> GEM Chapters
            </a>
          </div>
        </div>
        <div className="main-div-of-map">
          <div className="map-main-div">
            <div className="btn-div-map">
              {/* Show default data if no state is selected and not hovering */}
              {!selectedState && !isHovered && (
                <div className="card-map-main">
                  <div className="map-detail-card">
                    <img
                      src={defaultData.defaultSecretary.img}
                      alt="Chair Man"
                      className="map-detail-img"
                    />
                    <p className="ecoList">{defaultData.defaultSecretary.name}</p>
                    <p className="ecoList">{defaultData.defaultSecretary.post1}</p>
                    <p className="ecoList">{defaultData.defaultSecretary.organization}</p>
                  </div>
                  <div className="map-detail-card">
                    <img
                      src={defaultData.defaultCoChair.img}
                      alt="Co-Chair Man"
                      className="map-detail-img"
                    />
                    <p className="ecoList">{defaultData.defaultCoChair.name}</p>
                    <p className="ecoList">{defaultData.defaultCoChair.post2}</p>
                    <p className="ecoList">{defaultData.defaultCoChair.organization}</p>
                  </div>
                </div>
              )}

              {/* Show selected state details */}
              {selectedState && (
                <div className="map-detail-container">
                  <div className="map-cardHeading">
                    {selectedState && <h3 className="state-name">{selectedState.name}</h3>}
                  </div>
                  <div className="card-map-main">
                    <div className="map-detail-card">
                      <img src={selectedState.chairMan} alt="Chair Man" className="map-detail-img" />
                      <p className="ecoList">{selectedState.chairManName}</p>
                      <p className="ecoList">{selectedState.post1}</p>
                    </div>

                    <div className="map-detail-card">
                      <img src={selectedState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
                      <p className="ecoList">{selectedState.coChairManName}</p>
                      <p className="ecoList">{selectedState.post2}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="map-container">
              <div className="map-detail-container" style={{ display: "flex" }}>
                {hoverState && !selectedState && (
                  <div className="card-map-main">
                    <div className="map-detail-card">
                      <img src={hoverState.chairMan} alt="Chair Man" className="map-detail-img" />
                      <p className="ecoList">{hoverState.chairManName}</p>
                      <p className="ecoList">{hoverState.Post1}</p>
                      <p className="ecoList">{hoverState.organization1}</p>
                      <p className="ecoList">{hoverState.StateName}</p>
                    </div>

                    <div className="map-detail-card">
                      <img src={hoverState.coChairMan} alt="Co-Chair Man" className="map-detail-img" />
                      <p className="ecoList">{hoverState.coChairManName}</p>
                      <p className="ecoList">{hoverState.Post2}</p>
                      <p className="ecoList">{hoverState.organization1}</p>
                      <p className="ecoList">{hoverState.StateName}</p>
                    </div>
                  </div>
                )}
              </div>
              <SVGMap
                map={India}
                onLocationClick={onLocationClick}
                onLocationMouseOver={onLocationHover}
                onLocationMouseOut={onLocationMouseOut}
                locationClassName={locationClassName}
              />
              {Object.keys(stateData).map((stateName) => {
                const coordinates = stateCoordinates[stateName];
                if (coordinates) {
                  return (
                    <div
                      key={stateName}
                      style={{
                        position: "absolute",
                        left: coordinates.x,
                        top: coordinates.y,
                        fontSize: "15px",
                        color: "red",
                      }}
                    >
                      <div className="State-location-flexBox">
                        <FontAwesomeIcon icon={faLocationDot} className="location-icon" />
                        <p className="stateName">{stateName}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewGeoEcoSystemTest;
