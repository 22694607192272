import React from 'react'
import GEMDimond from '../assets/GemRating-bg/GEMRatingDimond.png'
import RatingImg1 from '../assets/GemRating-bg/ratingImg-1.png'
import ListBoxImg from '../assets/GemRating-bg/GemListBG.png'
import RatingImg2 from '../assets/GemRating-bg/GemRatingImg-2.png'
import GemScoreLogo from '../assets/GemRating-bg/Gem-scoredl=-logo.png'
import GemLevelLogo from '../assets/GemRating-bg/Gem-level-logo.png'
import GemDimondLogo from '../assets/GemRating-bg/Gem-dimond-logo.png'
import IsoPointer from '../assets/iso-img/Pointer-iso.png'
import PointerImg from '../assets/rating-img/Pointer-image.png'
import LevelPointer from '../assets/GemRating-bg/bullet-circle-2.png'
import StarIcon from '../assets/GemRating-bg/StarIcon.png'
import './NewGemRating.css'

const gemData = [
  { sno: 1, points: 'All essential requirements and 40 - 49 points', gemLevel: 'GEM 1', gemImages: [GEMDimond] },
  { sno: 2, points: 'All essential requirements and 50 - 64 points', gemLevel: 'GEM 2', gemImages: [GEMDimond, GEMDimond] },
  { sno: 3, points: 'All essential requirements and 65 - 84 points', gemLevel: 'GEM 3', gemImages: [GEMDimond, GEMDimond, GEMDimond] },
  { sno: 4, points: 'All essential requirements and 85 - 104 points', gemLevel: 'GEM 4', gemImages: [GEMDimond, GEMDimond, GEMDimond, GEMDimond] },
  { sno: 5, points: 'All essential requirements and 105 points or above', gemLevel: 'GEM 5', gemImages: [GEMDimond, GEMDimond, GEMDimond, GEMDimond, GEMDimond] },
];

const NewGemRating = () => {
  return (
    <div>
        <div className="GemRating-mainDiv">
       <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> GEM Rating
          </a>
        </div>
      </div>

      <div className="GemRating-BOx-Main">
<div className="Rating-flex-box-1">
      <div className="gem-rating-para-div">
        <h3 style={{textAlign: 'center'}}>About GEM Rating</h3>
        <p className="gem-rating-para" id='Gem-rating-para-color'>
        Sustainable buildings are playing a critical role in the development of many emerging economies. India’s Sustainable building market is estimated to double by 2022 at 10 billion sqft, valuing around USD 35-50 billion, driven by increasing awareness level, environmental benefits and government support.  India has emerged as one of the leading countries in terms of sustainable building projects and ranks only second after US in terms of number of green projects and built-up area. Still this is only about 5% of total buildings in India, and hence there is huge potential for further penetration of sustainable building design. 
        </p>

        <p className="gem-rating-para" id='Gem-rating-para-color'>
        In order to complement in India’s Sustainability Movement and take it to the next level, <b>ASSOCHAM </b>  has launched the “GEM Sustainability (Green) Certification Program” with the objective to promote environment friendly green building design and construction. GEM Sustainability Certification Rating Program is based upon BEE ECBC 2017 and NBC 2016. Through this initiative, ASSOCHAM do award the Sustainability Certification Rating to Housing, Urban Developments, Residential, Commercial, Hotels, College, Universities, Schools, Factory buildings and related developments.
        </p>
        </div>

        <div className="rating-img-1">
          <img src={RatingImg1} alt="" />
        </div>

        </div> {/* Flex end */}
        </div>
        <div className="squreEffectBox-gemRating">
            <h2 className="GemRating-paraHeading">GEM Sustainability Certification Rating Program</h2>
        </div>
        <div className="GemRatingList-box">
        <h3 className="GemRating-subHeading">All existing, new and upcoming buildings can participate in this program.</h3>
        {/* <div className="ListBgImg">
          <img src={ListBoxImg} alt="" />
        </div> */}
        <div className="GemList-div">
       
          <ul className="GemListItems" id='GemListItems-Program'>
         
            <li className="GemList" id='GemList-Program'>
            <div className="iso-list-pointer-img">
          <img src={IsoPointer} alt="" />
        </div>
        <p className="Rating-text-poniter">GEM Sustainability Certification Rating Program aims to address the sustainability of a given development throughout its lifecycle from design through construction to operation. GEM Sustainability Certification Reference Guide provides design guidance and detailed requirements for rating a project’s potential performance.</p>
              </li>
            <li className="GemList" id='GemList-Program'>
            <div className="iso-list-pointer-img">
          <img src={IsoPointer} alt="" />
        </div>
        <p className="Rating-text-poniter"> Project team can go for a Certification of Intent (Pre-certification / Provisional Certification) rating during pre-design, design or construction stages of the project and Final Certification rating when the building is complete.</p>
             </li>
            <li className="GemList" id='GemList-Program'>
            <div className="iso-list-pointer-img">
          <img src={IsoPointer} alt="" />
        </div>
        <p className="Rating-text-poniter"> Pre-certification / Provisional Certification / Certification of Intent rating will be awarded within 50 days from the date of the project registration with ASSOCHAM depending upon the construction stages of the project.</p>
             </li>
            <li className="GemList"id='GemList-Program'>
            <div className="iso-list-pointer-img">
          <img src={IsoPointer} alt="" />
        </div>
        <p className="Rating-text-poniter">ASSOCHAM is offering special onetime fee to Project</p>
              </li>
          </ul>
        </div>
      </div>

      <div className="GemRatingLevelDiv">

        <div className="GemRating_child">
       
          <div className="Domo-flexBox-GemRating">
          
          <div className="demo-divGemRating"></div>

            <div className="GemRating-levelPara">
            <h3 className="GemRating-subHeading-level">GEM Sustainability Certification Rating levels </h3>
            <div className="Gem-Leveling-pointer-text">
              <div className="Gem-level-pointer" id='Gem-level-pointer-1'>
                <img src={StarIcon} alt="" />
              </div>
                <p className="GemRating-level-text" style={{width: '70%'}}>GEM Sustainability Certification Rating is organized into Thirty Principles that are fundamental to a more sustainable development. There are some Essential and Suggested requirements of each Principle. Points are awarded for each Suggested requirement achieved.</p>
                </div>
                <div className="Gem-Leveling-pointer-text" id='Gem-level-pointer-2'>
                <div className="Gem-level-pointer">
                <img src={StarIcon} alt="" />
              </div>
                <p className="GemRating-level-text" style={{width: '80%'}}>There is a 0-135 point scale. Project will achieve GEM 1 to GEM 5 rating levels as per the requirements fulfilled and scores achieved by the project. This depends upon the project design which includes building architectural and elevation design, materials used during construction, HVAC, lighting and plumbing system designs, water and energy consumption of the building.</p>
                </div>
                <div className="Gem-Leveling-pointer-text" id='Gem-level-pointer-3'>
                <div className="Gem-level-pointer">
                <img src={StarIcon} alt="" />
              </div>
                <p className="GemRating-level-text" style={{width: '90%'}}>To achieve a GEM Sustainability Certification Rating, all Essential Principle requirements must be fulfilled along with a minimum number of Principle points. GEM 5 will be the highest achievable rating level in this program.</p>
                </div>
            </div>
            
            </div>
            
        </div>
        
      </div>
      
      <div className="GemRating-certification-main">
        <div className="GemRating-text-para-box">
          <div className="GEM-rating-Cetrification-flexBox-div">
            <div className="Gem-Rating-Certification-text-box">
              <ul className="GemRating-Certification-Lists">
              <li className="Gem-Certification-text">
              <div className="enviro-ponter">
                    <img src={PointerImg} alt="" />
                  </div>
                Review of all design documents such as Architectural, Mechanical, Electrical, Plumbing, and Landscape by Sustainability experts for further value addition from a green building perspective.</li>
              <li className="Gem-Certification-text">
                  <div className="enviro-ponter">
                    <img src={PointerImg} alt="" />
                  </div>
                Energy and water-efficient building design that will reduce the energy and water consumption of the building.</li>
              <li className="Gem-Certification-text">
              <div className="enviro-ponter">
                    <img src={PointerImg} alt="" />
                  </div>
                Design of a building that will utilize maximum daylight, fresh air, and provide a healthy environment to the building occupants.</li>
              <li className="Gem-Certification-text">
              <div className="enviro-ponter">
                    <img src={PointerImg} alt="" />
                  </div>
                Sustainability Certification rating will give additional marketing mileage to the projects over other conventional buildings.</li>
              </ul>
            </div>
            <div className="GemRating-certification-Img">
              <img src={RatingImg2} alt="" />
            </div>

          </div> {/* Flex end */}
        </div>
      </div>

        <div className="GemRating-dimond-box">
          <div className="Gem-Dimond-data-div">
        <table className="gem-table">
        <thead>
          <tr>
            {/* <th>S.No.</th> */}
            <th><div className="Scored-logo">
              <img src={GemScoreLogo} alt="" /></div></th>
            <th><div className="Gem-level-logo">
              <img src={GemLevelLogo} alt="" /></div></th>
            <th><div className="Gem-Dimond-logo">
              <img src={GemDimondLogo} alt="" /></div></th>
          </tr>
        </thead>
        <tbody>
          {gemData.map((gem, index) => (
            <tr key={index}>
              {/* <td>{gem.sno}</td> */}
              <td>{gem.points}</td>
              <td>{gem.gemLevel}</td>
              <td>
                <div className="gem-image-group">
                  {gem.gemImages.map((image, idx) => (
                    <img
                      key={idx}
                      src={image}
                      alt={gem.gemLevel}
                      className="gem-image"
                    />
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
        </div>
{/*  */}


      </div>
    </div>
  )
}

export default NewGemRating
