// import React, { useState, useEffect } from 'react';
// import '../componant/NewResource.css';
// import Img1 from '../assets/Resource/NewBuilding.png';
// import Img2 from '../assets/Resource/ExistingBuilding.png';

// import Img3 from '../assets/Resource/GemHome.png';
// import Img4 from '../assets/Resource/FactoryBuilding.png';
// import Img5 from '../assets/Resource/wareHouse&Logistics.png';
// import Img6 from '../assets/Resource/HealthCare.png';
// import { Link } from 'react-router-dom';
// import './ResourceSlider.css';
// import axios from 'axios';

// const pdfResources = [
//     { title: 'Download PDF 1', link: 'https://green-assocham.com/property/ckfinder_img/files/REFERENCE%20GUIDE%20(1).pdf', image: Img1 },
//     { title: 'Download PDF 2', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM_GEM_EB_Reference_Guide_Oct_2018-Final.pdf', image: Img2},
//     { title: 'Download PDF 3', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img3 },
//     { title: 'Download PDF 4', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img4 },
//     { title: 'Download PDF 5', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM%20GEM%20Green%20Warehouse%20%26%20Logstics%20Campus%20-%20May%202021.pdf', image: Img5 },
//     { title: 'Download PDF 6', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM%20Sustainable%20Healthcare%20Buildings.pdf', image: Img6 },
// ];

// const ResourceSlider = () => {
//     const [modalOpen, setModalOpen] = useState(false);
//     const [selectedPdf, setSelectedPdf] = useState(null);
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         designation: '',
//         message: ''
//     });
//     const [formError, setFormError] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [currentIndex, setCurrentIndex] = useState(0);
    
//     // Swipe variables
//     const [startX, setStartX] = useState(0);
//     const [isSwiping, setIsSwiping] = useState(false);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             nextSlide();
//         }, 4000);
//         return () => clearInterval(interval);
//     }, [currentIndex]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setFormError('');

//         if (!formData.name || !formData.email || !formData.phone || !formData.designation || !formData.message) {
//             setFormError('All fields are required.');
//             return;
//         }

//         setLoading(true);

//         try {
//             await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);

//             setFormData({
//                 name: '',
//                 email: '',
//                 phone: '',
//                 designation: '',
//                 message: ''
//             });

//             if (selectedPdf) {
//                 const link = document.createElement('a');
//                 link.href = selectedPdf;
//                 link.download = selectedPdf.split('/').pop();
//                 link.click();
//                 setModalOpen(false);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setFormError('Failed to submit data. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDownloadClick = (link) => {
//         setSelectedPdf(link);
//         setModalOpen(true);
//     };

//     const nextSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % pdfResources.length);
//     };

//     const prevSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + pdfResources.length) % pdfResources.length);
//     };

//     const getDisplayedResources = () => {
//         return Array.from({ length: 3 }, (_, i) => pdfResources[(currentIndex + i) % pdfResources.length]);
//     };

//     // Swipe Event Handlers
//     const handleTouchStart = (e) => {
//         setStartX(e.touches[0].clientX);
//         setIsSwiping(true);
//     };

//     const handleTouchMove = (e) => {
//         if (!isSwiping) return;
//         const currentX = e.touches[0].clientX;
//         const diffX = startX - currentX;

//         if (diffX > 50) {
//             nextSlide();
//             setIsSwiping(false);
//         } else if (diffX < -50) {
//             prevSlide();
//             setIsSwiping(false);
//         }
//     };

//     const handleTouchEnd = () => {
//         setIsSwiping(false);
//     };

//     return (
//         <div className="SliderResorce-main">
             
//         <div 
//             className="new-resource-container" 
//             onTouchStart={handleTouchStart} 
//             onTouchMove={handleTouchMove} 
//             onTouchEnd={handleTouchEnd}
//         >
//             {/* <h2>Available Resources</h2> */}
           

//             <div className="carousel-container">
//             <button className="carousel-button left slick-prev" onClick={prevSlide}>‹</button>
                
//                 {/* <button className="carousel-button left" onClick={prevSlide}>‹</button> */}
//                 <div className="resource-cards" id='resourceCards-Slider'>
//                     {getDisplayedResources().map((pdf, index) => (
//                         <div key={index} className="resource-card">
//                             <img src={pdf.image} alt={pdf.title} className="resource-banner" />
//                             {/* <h3>{pdf.title}</h3> */}
//                             <button className='Resource-btn' onClick={() => handleDownloadClick(pdf.link)}>Download</button>
//                         </div>
//                     ))}
//                 </div>
//                 <button className="carousel-button right slick-next" onClick={nextSlide}>›</button>
//             </div>

//             {modalOpen && (
//                 <div className="modal" role="dialog" aria-labelledby="modal-title" aria-modal="true">
//                     <div className="modal-content">
//                         <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
//                         <h3 id="modal-title">Register to Access PDF</h3>
//                         <form onSubmit={handleSubmit}>
//                             {formError && <p className="error">{formError}</p>}
//                             <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
//                             <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
//                             <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
//                             <input type="text" name="designation" placeholder="Designation" value={formData.designation} onChange={handleChange} required />
//                             <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
//                             <button type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Register'}</button>
//                         </form>
//                     </div>
//                 </div>

//             )}
            
//         </div>
//         <button className="gallery-card-btn" id='gallery-card-btn-slider'>
//                 <Link to= '/resources'>   Show All </Link> 
//                 </button>

//         </div>
//     );
// };

// export default ResourceSlider;


// new code 
import React from 'react';
import Slider from 'react-slick';
import Img1 from '../assets/Resource/NewBuilding.png';
import Img2 from '../assets/Resource/ExistingBuilding.png';
import Img3 from '../assets/Resource/GemHome.png';
import Img4 from '../assets/Resource/FactoryBuilding.png';
import Img5 from '../assets/Resource/wareHouse&Logistics.png';
import Img6 from '../assets/Resource/HealthCare.png';
import '../componant/NewResource.css';
import './ResourceSlider.css';
import { Link } from 'react-router-dom';

const pdfResources = [
    { title: 'Download PDF 1', link: 'https://green-assocham.com/property/ckfinder_img/files/REFERENCE%20GUIDE%20(1).pdf', image: Img1 },
    { title: 'Download PDF 2', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM_GEM_EB_Reference_Guide_Oct_2018-Final.pdf', image: Img2 },
    { title: 'Download PDF 3', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img3 },
    { title: 'Download PDF 4', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM_Sustainability_Certification_Program_Reference_Guide%20(2).pdf', image: Img4 },
    { title: 'Download PDF 5', link: 'https://green-assocham.com/property/ckfinder_img/files/ASSOCHAM%20GEM%20Green%20Warehouse%20%26%20Logstics%20Campus%20-%20May%202021.pdf', image: Img5 },
    { title: 'Download PDF 6', link: 'https://green-assocham.com/property/ckfinder_img/files/GEM%20Sustainable%20Healthcare%20Buildings.pdf', image: Img6 },
];

const ResourceCardSlider = ({ title, link, image }) => (
    <div className="SliderResource-card">
        <img src={image} alt={title} className="resource-card-img" id='slider-image' />
        {/* <h3>{title}</h3> */}
        {/* <a href={link} download className="download-btn">
            Download PDF
        </a> */}
    </div>
);

const ResourceSlider = () => {
    // Slick Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024, // Tablets
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // Small tablets and large phones
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480, // Mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div>
        <div className="slider-container" >
            <Slider {...settings}>
                {pdfResources.map((resource, index) => (
                    <div key={index} className="slider-item">
                        {/* <ResourceCard className="slider-image" 
                            title={resource.title} 
                            link={resource.link} 
                            image={resource.image}
                        /> */}
                        <div id="SliderResourceCard">
                        <ResourceCardSlider className="SliderResourceCard" 
                            title={resource.title} 
                            // link={resource.link} 
                            image={resource.image}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        <button className="gallery-card-btn" id='gallery-card-btn-slider' >
                <Link to= '/resources'>   Show All </Link> 
                </button>

        </div>
    );
};

export default ResourceSlider;
