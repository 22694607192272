import React from 'react'
import './LeanAssessment.css'
import LeanParaImg from '../assets/leanImg/LeanImgNew.png'
import LeanFooter from '../assets/leanImg/Footer-image-lean.png'
import BenifitImg from '../assets/leanImg/benifit-icon.png'
import AdvantageSvg from '../assets/leanImg/advantage-icon.png'
import IsoPointer from '../assets/iso-img/Pointer-iso.png'
import LeanPointerImg from '../assets/leanImg/LeanPointer.png'

const LeanAssessment = () => {
  return (
    <div>
        <div className="lean-main-bg">
       <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> Lean Assessment
          </a>
          <div className="lean-blur-div">
          <div className="text-container-lean">


</div>

<div className="text-para-div" id='lean-text-para-div'>

  <div className="lean-text-para-for-before">
 
  <div className="lean-top-flexBox">
  <div className="lean-para-div-top" id='Lean-para-div-bg'>
  <h3 style={{color: '#11253f', textAlign: 'center', position: 'relative', paddingTop: '30px'}}>Lean Rating System</h3>
<p className='text-para-text' id='text-color-lean'   >
<b>India</b> is well set to become a manufacturing hub. The world nowadays is very dynamic. The global factors have adversely affect the supply chain. This has brought an unpredictability in the raw material costing which has direct & significant impact on the Profit.
Apart from that, the organisation is facing competition from rivals and the era of customisation has resulted in everchanging demand of customers. So, it is very difficult to increase the price for organisation. 
</p>

{/* <p className='text-para-text' id='text-color-lean'   >


</p> */}

<p className='text-para-text' id='text-color-lean' >
The Raw material part and customer & competition part are external to organisation. So, the organisation has little control over it.  But the organisation has full control over what happens inside the organisation. The management can decide to optimise the process within organisation. The Lean implementation is such a tool which is helpful in achieving these goals. Lean was started by <b>Toyota</b> with famous <b>‘Toyota Production System’</b> and has been implemented successfully across various industries in different countries. 

</p>

<p className='text-para-text' id='text-color-lean' style={{paddingBottom: '0px'}} >
There are various activities/processes which does not create value in the eyes of the stakeholders. These non-value-added activities are termed as WASTE in the Lean philosophy. Lean methodology focuses on identifying different type of waste and strive to eliminate or reduce it. The Lean Manufacturing System emphasize <b>“Doing More with Less”</b> which enable organisation to <b>Produce more</b> at lesser <b>Cost</b> in lesser <b>Time</b>, in less <b>Space</b> with less <b>Inventory</b> & less <b>Human effort.
</b>
</p>
</div>
<div className="lean-img-box">
  <img src={LeanParaImg} alt="" />
</div>

</div>
{/* <p className='text-para-text' id='text-color-lean' style={{paddingBottom: '0px', paddingLeft: '130px'}} >
There are various activities/processes which does not create value in the eyes of the stakeholders. These non-value-added activities are termed as WASTE in the Lean philosophy. Lean methodology focuses on identifying different type of waste and strive to eliminate or reduce it. The Lean Manufacturing System emphasize <b>“Doing More with Less”</b> which enable organisation to <b>Produce more</b> at lesser <b>Cost</b> in lesser <b>Time</b>, in less <b>Space</b> with less <b>Inventory</b> & less <b>Human effort.
</b>
</p> */}
</div>
{/* flex div */}
<div className="lean-advantage-flex">
<div className="lean-advantage-div">
<div className="lean-blur-div-advantage">
<h4 className='Lean-advantage-heading'>Advantages</h4>
<div className="advantage-svg-div">
  <img src={AdvantageSvg} alt="" />
</div>
<p className='text-para-text' id='text-color-lean'  style={{paddingBottom: '0px', paddingLeft: '22px' , paddingRight: '22px'}}>
The Lean concepts were originated at <b>Toyota Production System</b>. There are numerous advantages of applying Lean Concepts including:

</p>


<ul className="lean-list">
  
    <li className="lean-data">
        <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Reducing Waste</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Increasing Productivity</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Reducing Lead time & Throughput time</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">  Process Standardization</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Business Process Optimisation & Capacity utilisation</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Manpower optimisation</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Optimise material flow & layout</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">  On time Delivery</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Increased cash flow</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Inventory & Store management</p>
    </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Inculcating good management systems</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Imbibing a culture of continuous improvement</p>
      </li>
</ul>

<p className='text-para-text' id='text-color-lean'  style={{paddingBottom: '10px', paddingLeft: '22px', paddingRight: '22px'}} >
All the above parameters help in the performance & productivity of the organisation. It lessens the requirement of the working capital & shortens the turnover cycle.  This in turn leads to more profitability for the organisation. 
</p>
</div>
</div>

<div className="lean-benifit-div">
<div className="lean-blur-div-advantage">
  {/* <div className="lean-heading-box-flex"> */}
  <div className="img-heading-svg">
  <img src={BenifitImg} alt="" />
</div>
<h4 className='Lean-benefit-heading'>Benefits</h4>

{/* </div> */}
<p className='text-para-text' id='text-color-lean'  style={{paddingBottom: '10px', paddingLeft: '22px', paddingRight: '22px'}}>
{/* <b></b><br /> */}
The parameters for ASSOCHAM Lean rating systems (ALRS) are designed in such a way to ensure the maximum benefits for organisations achieving 5 GEM. Higher the GEM rating, higher is the application of LEAN tools & in-turn, higher is the benefits for the organisation. Several tangible & in-tangible benefits are as follows: -

</p>
<ul className="lean-list">
    <li className="lean-data">

      Higher profitability of the organisation</li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">Lesser capital required</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Smooth flow of information</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Increase customer satisfaction</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Enhanced workplace management</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Lesser defects</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text">  Increased employee satisfaction</p>
     </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Optimum utilisation of resources</p>
      </li>
    <li className="lean-data">
    <div className="iso-list-pointer-img">
          <img src={LeanPointerImg} alt="" />
        </div>
        <p className="lean-list-text"> Contribution in preserving national resources</p>
      </li>
</ul>
</div>
</div>
</div>



</div>
</div>
    </div>
    {/* <div className="leanImg-2box">
      <img src={LeanFooter} alt="" />
    </div> */}
    </div>
  )
}


export default LeanAssessment;
