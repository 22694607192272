import React from 'react';
import './PrivacyPolicy.css'
const PrivacyPolicy = () => {
  return (
    <div>

<div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> Privacy Policy
          </a>
        </div>
      </div>


    <div className="privacy-policy-page">
        <div className="privacy_upperChild-Div">
      <header className="privacy-heading">
        <h2 className='privacy-policyHeading'>ASSOCHAM Terms of Services and Privacy Policy</h2>
      </header>

      <section className="terms-of-service">
      <h4 className="privacy-subHeading">Applicability:</h4>
        <p className='Privacy-para'>
            
            By accessing and using our (GEM ASSOCHAM’s) Website, you consent to our General Terms and Conditions, Privacy Policy, and the Refund Policy (“Terms and Policies”) provided below, and your access to and use of our Website is subject to the Terms and Policies. You are expected to carefully read and understand the Terms and Policies and to keep yourself updated of any change/amendment therein before accessing and using our website. If you do not agree to the Terms and Policies, or any part thereof, you must immediately stop accessing and using our Website. The data/information collected is stored and processed by, and this website is operated by, and contents therein are owned, or licensed, by: ASSOCHAM, 4th Floor, YMCA Cultural Centre and Library Building, 01, Jai Singh Road, New Delhi - 110001 Phone: 46550555(Hunting Line) Fax: 01123347008/9 Email: assocham@nic.in
        </p>
        <h4>Scope:</h4>
        <p className='Privacy-para'>
        The Terms and Policies apply only to the Website (www.assocham.org/) and its sub-domains. If, in any event, you are re-directed to a third-party website, the respective terms and policies of the host of that third-party website shall be applicable. You are expected to monitor the URL (Uniform Resource Locator)/address of the webpage you visit to confirm that you have not been re-directed to a third-party website.
        </p>
        <h4>Privacy Policy</h4>
        <p className='Privacy-para'>
        ASSOCHAM is committed to ensuring that your privacy and personal data is always protected. To provide our services to you, we may ask you to provide certain information by which you can be identified while using this Website. You can be assured that any data or information we request or collect will only be used and processed in accordance with this Privacy Policy and in accordance with applicable laws. ASSOCHAM may change or amend this Privacy Policy from time to time by updating this page. You are expected to check this page whenever you access and use this Website to ensure that you have understood and agreed to the terms and conditions and this Privacy Policy, as amended and updated from time to time. All the information, branding, including logo of ASSOCHAM is the proprietary to ASSOCHAM, and use of its logo or watermark of logo by anyone either being used in any event, seminar, letterheads, printed material, official website or any other social and print media without express written permission from ASSOCHAM shall be violation of the intellectual property rights of ASSOCHAM and ASSOCHAM shall have every right to initiate legal action for such act.
        </p>
        <h3>Information Collected:</h3>
        <p className='Privacy-para'>
        We may collect the following information:
        <ul className="privacy-list-items">
            <li className="privacyList">Information filled on the web forms (‘Contact us’, ‘Employee Page’, etc.). You are not obligated to provide any information though the forms for general use of our Website. However, we might require to fill certain web-forms to enable us to provide you additional features and services.</li>
            <li className="privacyList">Cookies: Cookies are used on this Website. Cookies are small file stored on your computer when you visit our website, and can be used to store information like preferences, past transactions, etc. These are used to offer additional features and improve your experience on our website and/or to provide better services on subsequent and continued use of the Website. You can choose to disable some of the cookies from your browser preferences, however, some cookies may be required in order to access the website.</li>
            <li className="privacyList">Sensitive Personal Data: We do not store or process any Sensitive Personal Data or Information except passwords in event you choose to create a profile on our website. We also provide online payment options to provide certain services to you, for which Sensitive Personal Data or Information may be collected and processed by the Payment Gateway Service Provider. You are expected to read, understand, and agree to the terms and conditions and policies of the Payment Gateway Service Provider. Providing such information is not mandatory for use of the Website and you can choose not to provide the same, however, it may be required for some of its features to work.</li>
        </ul>
        </p>
      </section>

      <section className="privacy-policy">
        <h3>Processing and Storing of information/data collected:</h3>
        <p className='Privacy-para'>
        Any information/data collected from you or by your access and use of our Website is stored and processed merely to provide you the services and features, and to improve your experience on our website and of our services. We may use the said information and data to contact you, customize our website to provide you a personalized experience, make recommendations, for internal record-keeping and administrative purposes, analytics, market research, campaigns, manage subscriptions and memberships, send you promotional offers, newsletters, etc. You may opt-out of newsletters and promotional offers at any time should you choose to do so. We may share the personal data or information collected with any third-parties only to the extent the same is required for us to provide our services to you. None of the said personal data or information is sold by us to any third-party.
        </p>
        <h3>Other features and third-party integration:</h3>
        <p className='Privacy-para'>
        We may integrate features provided by third-parties to enhance your experience, provide certain features and services, analytics, advertisements, etc.
        The third-parties include, but are not restricted to:
        <ul className="privacy-list-items">
            <li className="privacyList">Google analytics</li>
            <li className="privacyList">Javascript libraries like jquery, bootstrap, sweetalert etc</li>
            <li className="privacyList">Google translate</li>
            <li className="privacyList">Social Media feeds from Facebook and Twitter</li>
        </ul>
        </p>
        <h3>Privacy of Children:</h3>
        <p className='Privacy-para'>
        We do not knowingly collect or process any personal information from children under the age of 18 years or provide any services to such persons through our website. If you are under the age of 18 years, please immediately stop accessing and using our website and do not submit any personal information or data through our website.
        </p>
        <h3>Security</h3>
        <p className='Privacy-para'>
        We are committed to ensuring that any information or data shared by you to us is safe and secure. In order to prevent unauthorised access, theft, alteration, or disclosure, we have put in place industry accepted security measures. Although we have taken necessary steps to ensure that this website remains safe and secure, due to possibility of third-party interference and malicious attacks, we do not warrant that the website will, at all times, remain safe and secure. We shall not be held liable for any loss or damage arising out of or in relation to unintended use or disclosure of data.
        </p>
        <h3>Jurisdiction</h3>
        <p className='Privacy-para'>
        We maintain our website from New Delhi, India. This Policy and your access and use of our website and services is governed exclusively by the laws of India and courts in New Delhi will have exclusive jurisdiction over the same. The User hereby consents and submits to the exclusive jurisdiction to the courts of New Delhi, India regarding any disputes arising out of or in connection with this Website or use thereof.
        </p>
        <h3>Grievance Officer:</h3>
        <p className='Privacy-para'>
        In case of any discrepancies and grievances with respect to processing of information, you may contact the following:
        <ul className="privacy-contact-list">
            <li className="privacy-contact">Harinder Saini (Grievance Officer)</li>
            <li className="privacy-contact">Contact: it@assocham.com</li>
        </ul>
        </p>

        <h3>General Terms and Conditions</h3>
        <p className='Privacy-para'>
        We may integrate features provided by third-parties to enhance your experience, provide certain features and services, analytics, advertisements, etc.
        The third-parties include, but are not restricted to:
        <ul className="privacy-list-items">
            <li className="privacyList-term">This Privacy Policy governs the manner in which ASSOCHAM collects, uses, maintains and discloses information collected from users (each, a "User") of the assocham.org website ("Site"). This privacy policy applies to the Site and all products and services offered by ASSOCHAM.</li>
            <li className="privacyList-term"> In no event ASSOCHAM will not be liable for any loss or damage including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from inaccuracy of data or information, loss of data or profits out of, or in connection with, the use of this website or our services.</li>
            <li className="privacyList-term">This website may contain links to other websites, which are not under the control of ASSOCHAM. ASSOCHAM has no control over the nature, content and availability of such websites. The inclusion of any links does not necessarily imply a recommendation or endorsement of the content or views expressed within them by ASSOCHAM.</li>
            <li className="privacyList-term">Every effort is made to keep the website up and running smoothly. However, ASSOCHAM takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues or circumstances beyond our control.</li>
            <li className="privacyList-term">ASSOCHAM shall not be liable for any unauthorized use of this website and such use shall be a criminal offence and may give rise to claim for loss and damages incurred by ASSOCHAM against such user including any pecuniary damages and legal costs incurred by ASSOCHAM to other party including its members.</li>
            <li className="privacyList-term"><h4>
            Refund Policy</h4>
            <p className="privacy-list-text">Any fees, including membership, subscription, sponsorship, exhibition, delegate, stall, kiosk, etc. once made shall not be refunded except under certain conditions. Refund of such fees will be made in case the respective service is not provided or payment has been made twice erroneously for the same services, the fee will either be adjusted against future services or refunded after due verification within a period of 60 days thereof, at the option of ASSOCHAM.</p>
            </li>

            <li className="privacyList-term"><h4>
            Cancellation Policy</h4>
            <p className="privacy-list-text">Cancellation will only be allowed in the following events:</p>
            <ul className="privacyChildList">
                <li className="childList-text">In case the event for which payments were made is postponed for an indefinite period or the next date proposed for the event is after 90 (ninety) days of the previous proposed date;</li>
                <li className="childList-text">In case the event for which payments were made is not held due to any conditions which are beyond the control of ASSOCHAM, including force majeure conditions, government orders/directions, riots, fire, strikes, curfews, pandemic, etc.</li>
                <li className="childList-text">In case of participant having paid participation fee, the participant having intimated about withdrawal 15 (fifteen) days prior to the date of event.
                No fees/amount, including participation fee, membership fee, sponsorship, etc. shall be refunded in any other event.</li>
                
            </ul>
            </li>
            <li className="privacyList-term"><h4>
            Governing Law and Dispute Resolution</h4>
            <p className="privacy-list-text">
            <ul className="privacyChildList">
                <li className="lawList"><b>8.1.</b> All questions, claims, issues, disputes, questions and differences of any kind whatsoever which may arise between the parties arising out of or in connection with these terms and conditions, or the execution, interpretation, validity, performance, breach or termination thereof shall be resolved and settled through arbitration by a sole arbitrator to be appointed in accordance with the rules of the Indian Council of Arbitration, New Delhi. The Award so made shall be final and binding on the parties.</li>
                <li className="lawList"><b>8.2.</b> The seat and venue of the Arbitration proceedings shall be at New Delhi, and all proceedings shall be conducted in New Delhi, India in English language in accordance with Indian Laws (both substantive and procedural) under the Arbitration and Conciliation Act, 1996 as amended and re-enacted from time to time. The arbitration proceedings and the award to be made there under shall be subject to and shall be filed only before the courts having territorial jurisdiction over New Delhi.</li>
                <li className="lawList"><b>8.3.</b> These terms and conditions and the rights and obligations of the parties shall be construed and governed by Indian laws.</li>
            </ul>
            </p>
            </li>
            <li className="privacyList-term">
            <p className="privacy-list-text">ASSOCHAM may, in its sole discretion, alter, supplement, or amend these Terms and Conditions from time to time by updating this page. You are expected to check this page whenever you access and use this Website to ensure that you have understood and agreed to the terms and conditions, as amended and updated from time to time.</p>
            
            </li>
        </ul>
        </p>


      </section>
      </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
