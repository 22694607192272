import React from 'react';
import './NewGemCps.css';
import { Link } from 'react-router-dom';
import ListImg from '../assets/GemCps-img/Gem-cps-listImg.png'
import GemPointerImg from '../assets/GemCps-img/GemCps-PointerImg.png'
import { circle } from 'leaflet';
import GemRegistrationPage from '../StepDesign/GemRegistrationPage';

const NewGemCps = () => {
  // Array of steps with titles
  const steps = [
    { id: 1, title: "Register for GEM", route: "/CpsRegisterForm", BtnOption: 'Click Here'},
    { id: 2, title: "Online Payment", route: "/paymentPage", BtnOption: 'Click Here' },
    { id: 3, title: "Schedule Your Test",  },
    { id: 4, title: "Download Reference Guide", route: "/resources", BtnOption: 'Click Here' },
    { id: 5, title: "Take the Online Test",  }
  ];
  const CpsCard = [
    {id: 1, Description: 'Start Your Journey with registration to become a GEM Certfied Professional', circle: ""},
    {id: 2, Description: 'Once your registration is successful, you will receive the following link to make the payment for GEM CP online \n test fee: Fee details: - Rs. 5,900 for Professionals - Rs. 4,720 for Faculties & Students (Including GST) Payment options available. You can also contact us for cheque/DD/NEFT payments.', circle: ""},
    {id: 3, Description: 'After payment, you will receive a link to schedule your online test. You can schedule the test between Monday to Friday from 10 AM to 5 PM.', circlez: ""},
    {id: 4, Description: 'The GEM Sustainability Certification Program reference guide can be downloaded from our website for FREE'},
    {id: 5, Description: 'You will receive a link to take the online test as per your scheduled time',
        color: 'white',
    },

  ]

  return (
    <div>
      <div className="GemCps-mainDiv-Box">
      <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> GEM Certified Professional
          </a>
        </div>
      </div>
        <div className="GemCps-Element-div">
          <div className="GemCps-Heading-text">
            <h3 className="GemTop-Heading">ASSOCHAM GEM Certified Professional (GEM CP) Online Test Registration Steps</h3>
            <p className="GemCps-Text">
              ASSOCHAM has initiated the “GEM Sustainability (Green) Certification Program ” with an objective to promote environment-friendly green building design and construction. Through this initiative, ASSOCHAM awards the Sustainability Certification Rating to Housing, Urban Development, Residential, Commercial, Hotels, College, Universities, Schools, and Factory buildings and developments. All existing, new, and upcoming buildings can participate in this program. You can become a GEM CP by taking the GEM CP test remotely. You can take the test in the comfort of your home or workplace. This convenience will be valuable to you. The ASSOCHAM team of experts has made this possible for you.
            </p>

            <h3 className="GemTop-Heading">GEM CP Online Test Registration</h3>
            <p className="GemCps-Text">Use the following steps to register yourself for GEM CP online test, make online/offline payment, schedule the test, and appear for the test.</p>
          </div>

          <div className="GemCps-Steps">
            {steps.map((step) => (
                <Link to={step.route}>
              <div key={step.id} className={`GemCps-Step Circle${step.id}`}>
                {/* <span className="GemCps-Index">{step.id}</span> */}
                <p>{step.title}</p>
              <p id='GemCps-link'>{step.BtnOption}</p>
              </div></Link>
            ))}
          </div>
          {/* <div className="circle-flex-div-main">
            
          <div className="GemCps-cards">
            {CpsCard.map((CpsCards) =>(

                <div key={CpsCards.id} className={`GemCps-Steps-card Gemcps-Step-Cards-${CpsCards.id}`}>
                <p className="GemCps-Card-Text">
                    
                    {CpsCards.Description}</p>
                </div>
            ))}
          </div>
          </div> */}

          <GemRegistrationPage />
          


            <div className="GemCps-List-FlexBox">
                <div className="GemCps-List-Box">
                <h3 className="GemCps-listHeading">Instructions :-</h3>
                    <ul className="GemCps-list-items">
                        
                        <li className="GemCps-list">
                            <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" />
                                </div>
                                <p>Your test will be started on the scheduled time only. Paste the above link in a new tab at or just after the scheduled time and press enter. Your test will be started.</p>
                            </div>
                           </li>
                        <li className="GemCps-list">
                        <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" /></div>
                                <p>
                            You will be given 5 minutes to read the instructions and 90 minutes for taking the test. <br />
                            Read all the instructions and questions carefully.
                            </p>
                            </div>
                           
                        </li>
                        <li className="GemCps-list">
                        <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" /></div>
                                <p>There will be 100 questions in the test (Both objective type-single answer and True/False).</p>
                            </div>
                           </li>
                        <li className="GemCps-list">
                        <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" />
                                </div>
                                <p>During the test, you can move to any previous or next questions by clicking on the
                        “Previous” or “Next” tab respectively.</p>
                            </div>
                            </li>
                        <li className="GemCps-list"><div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" />
                                </div>
                            <p>You can review all the attempted questions by clicking on the “Review & Submit” tab
                        once.</p>
                        </div>
                        </li>
                        <li className="GemCps-list">
                        <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" />
                                </div>
                                <p>Once you are in the Test Review section, you can move to any question by just clicking
                        on that question directly.</p>
                            </div>
                            </li>
                        <li className="GemCps-list">
                        <div className="GemCps-Ponter-Flex-box">
                                <div className="pointerImg">
                                <img src={GemPointerImg} alt="" />
                                </div>
                                <p>You can finally submit the test by clicking on the “Final Submit” tab.
                        Do not close the test window during the test.</p>
                            </div>
                            </li>
                    </ul>
                </div>
                <div className="List-Img-Div">
                    <img src={ListImg} alt="" />
                    <h3 className="img-bottom-heading">Performance Criteria</h3>
                </div>
            </div>

        </div>
      </div>
    </div>
  );
};

export default NewGemCps;
