// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './GemExam.css';

// const GemExam = () => {
//   const [questions, setQuestions] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedOption, setSelectedOption] = useState('');
//   const [marks, setMarks] = useState(0);
//   const [timeRemaining, setTimeRemaining] = useState(2700); // 45 minutes in seconds
//   const [isTimerRunning, setIsTimerRunning] = useState(true);

//   useEffect(() => {
//     // Fetch questions from the API using axios
//     axios
//       .get(`${process.env.REACT_APP_API_URL}/api/exam/questions`)
//       .then((response) => {
//         const fetchedQuestions = response.data;
//         const uniqueQuestions = [];

//         // Loop through fetched questions and filter out duplicates based on the question text
//         fetchedQuestions.forEach((question) => {
//           const isDuplicate = uniqueQuestions.some(
//             (uniqueQuestion) => uniqueQuestion.question === question.question
//           );

//           // If the question is not a duplicate, add it to the uniqueQuestions list
//           if (!isDuplicate) {
//             uniqueQuestions.push(question);
//           }
//         });

//         setQuestions(uniqueQuestions); // Set filtered unique questions
//       })
//       .catch((error) => {
//         console.error('Error fetching questions:', error);
//       });

//     // Start the timer
//     const interval = setInterval(() => {
//       setTimeRemaining((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(interval); // Stop the timer when it reaches 0
//           setIsTimerRunning(false); // Disable further actions after timer ends
//           handleSubmit(); // Auto-submit when time is up
//           return 0;
//         }
//         return prevTime - 1;
//       });
//     }, 1000);

//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, []);

//   const handleOptionChange = (option) => {
//     if (isTimerRunning) {
//       setSelectedOption(option);
//     }
//   };

//   const validateAnswer = () => {
//     if (selectedOption === questions[currentIndex].right_ans) {
//       setMarks(marks + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (isTimerRunning && currentIndex > 0) {
//       setCurrentIndex(currentIndex - 1);
//       // setSelectedOption('');
//     }
//   };

//   const handleNext = () => {
//     if (isTimerRunning) {
//       validateAnswer();
//       if (currentIndex < questions.length - 1) {
//         setCurrentIndex(currentIndex + 1);
//         setSelectedOption('');
//       }
//     }
//   };

//   const handleSubmit = () => {
//     validateAnswer();
//     alert(`Test Submitted! Your total marks are: ${marks}`);
//   };

//   if (questions.length === 0) {
//     return <div>Loading questions...</div>;
//   }

//   const currentQuestion = questions[currentIndex];

//   // Format time remaining into mm:ss format
//   const minutes = Math.floor(timeRemaining / 60);
//   const seconds = timeRemaining % 60;
//   const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

//   // Collect available options dynamically
//   const options = [];
//   for (let i = 1; i <= 4; i++) {
//     const option = currentQuestion[`opt${i}`];
//     if (option) options.push({ value: `opt${i}`, label: option });
//   }

//   return (
//     <div className="exam-container">
//       <div className="timer">
//         <p className="exam-timer">Time Remaining: {formattedTime}</p>
//       </div>

//       <div className="question-container">
//         <p className="question">
//           <strong>Question {currentIndex + 1}:</strong> {currentQuestion.question}
//         </p>
//         <div className="options">
//           {options.map(({ value, label }) => (
//             <label className="question-option" key={value}>
//               <input
//               className='exam-option-box'
//                 type="radio"
//                 name="answer"
//                 value={value}
//                 checked={selectedOption === value}
//                 onChange={() => handleOptionChange(value)}
//                 disabled={!isTimerRunning}
//               />
//               {label}
//             </label>
//           ))}
//         </div>
//       </div>

//       <div className="buttons">
//         <button
//           className="pre-exam-btn"
//           onClick={handlePrevious}
//           disabled={currentIndex === 0 || !isTimerRunning}
//         >
//           Previous
//         </button>
//         <button
//           className="next-exam-btn"
//           onClick={handleNext}
//           disabled={!isTimerRunning}
//         >
//           Next
//         </button>
//         <button
//           className="btn eaxm-submit-btn"
//           onClick={handleSubmit}
//           disabled={!isTimerRunning}
//         >
//           Submit
//         </button>
//       </div>

//       <div className="marks">Current Marks: {marks}</div>
//     </div>
//   );
// };

// export default GemExam;


// new code 

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GemExam.css';
import { useParams } from 'react-router-dom';

const GemExam = () => {
  const { rollNumber } = useParams(); 
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [marks, setMarks] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(2700); // 45 minutes in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [answeredQuestions, setAnsweredQuestions] = useState([]); // To track answered questions

  useEffect(() => {
    // Fetch the assigned questions for this rollNumber
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/exam/assigned-questions/${rollNumber}`)
      .then((response) => {
        setQuestions(response.data); // Set the questions for the user
      })
      .catch((error) => {
        console.error('Error fetching assigned questions:', error);
      });

    // Start the timer
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval); // Stop the timer when it reaches 0
          setIsTimerRunning(false); // Disable further actions after timer ends
          handleSubmit(); // Auto-submit when time is up
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [rollNumber]);

  const handleOptionChange = (option) => {
    if (isTimerRunning) {
      setSelectedOption(option);
    }
  };

  const validateAnswer = () => {
    if (selectedOption === questions[currentIndex]?.right_ans) {
      setMarks(marks + 1);
    }
    setAnsweredQuestions((prev) => [...prev, currentIndex]);
  };

  const handlePrevious = () => {
    if (isTimerRunning && currentIndex > 0) {
      validateAnswer();
      setCurrentIndex(currentIndex - 1);
      setSelectedOption(answeredQuestions[currentIndex - 1] || '');
    }
  };

  const handleNext = () => {
    if (isTimerRunning) {
      validateAnswer();
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setSelectedOption(answeredQuestions[currentIndex + 1] || '');
      }
    }
  };

  const handleSubmit = () => {
    validateAnswer();
    alert(`Test Submitted! Your total marks are: ${marks}`);
  };

  if (questions.length === 0) {
    return <div>Loading questions...</div>;
  }

  const currentQuestion = questions[currentIndex];

  // Format time remaining into mm:ss format
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  // Collect available options dynamically
  const options = [];
  for (let i = 1; i <= 4; i++) {
    const option = currentQuestion[`opt${i}`];
    if (option) options.push({ value: `opt${i}`, label: option });
  }

  return (
    <div className="exam-container">
      <div className="timer">
        <p className="exam-timer">Time Remaining: {formattedTime}</p>
      </div>

      <div className="question-container">
        <p className="question">
          <strong>Question {currentIndex + 1}:</strong> {currentQuestion.question}
        </p>
        <div className="options">
          {options.map(({ value, label }) => (
            <label className="question-option" key={value}>
              <input
                className='exam-option-box'
                type="radio"
                name="answer"
                value={value}
                checked={selectedOption === value}
                onChange={() => handleOptionChange(value)}
                disabled={!isTimerRunning}
              />
              {label}
            </label>
          ))}
        </div>
      </div>

      <div className="buttons">
        <button
          onClick={handlePrevious}
          disabled={currentIndex === 0 || !isTimerRunning}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentIndex === questions.length - 1 || !isTimerRunning}
        >
          Next
        </button>
        <button
          onClick={handleSubmit}
          disabled={!isTimerRunning}
        >
          Submit
        </button>
      </div>

      <div className="marks">Current Marks: {marks}</div>
    </div>
  );
};

export default GemExam;


// new new code 

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './GemExam.css';
// import { useParams } from 'react-router-dom';

// const GemExam = () => {
//   const { rollNumber } = useParams(); // Get rollNumber from URL parameters
//   const [questions, setQuestions] = useState([]); // State to hold questions
//   const [timeLeft, setTimeLeft] = useState(0); // Time remaining in milliseconds
//   const [examStartTime, setExamStartTime] = useState(''); // Exam start time
//   const [currentIndex, setCurrentIndex] = useState(0); // Track current question index
//   const [selectedOption, setSelectedOption] = useState(''); // Selected option
//   const [marks, setMarks] = useState(0); // Track marks
//   const [isTimerRunning, setIsTimerRunning] = useState(false); // Timer flag
//   const [answeredQuestions, setAnsweredQuestions] = useState(new Set()); // Track answered questions

//   useEffect(() => {
//     // Fetch exam details including questions and time left
//     axios
//       .get(`http://192.168.1.54:8090/api/exam/assigned-questions/${rollNumber}`)
//       .then((response) => {
//         const { examStartTime, timeLeft, questions } = response.data;
//         setExamStartTime(examStartTime); // This is the actual start time of the exam
//         setTimeLeft(timeLeft); // This is the time remaining in milliseconds
//         setQuestions(questions); // Set fetched questions
//         setIsTimerRunning(true); // Start the timer once questions are loaded
//       })
//       .catch((error) => {
//         console.error('Error fetching exam details:', error);
//       });

//     // Timer logic for countdown
//     const interval = setInterval(() => {
//       setTimeLeft((prevTime) => {
//         if (prevTime <= 0) {
//           clearInterval(interval);
//           setIsTimerRunning(false);
//           handleSubmit(); // Auto-submit when time is up
//           return 0;
//         }
//         return prevTime - 1000; // Decrease by 1 second
//       });
//     }, 1000);

//     return () => clearInterval(interval); // Cleanup the interval on component unmount
//   }, [rollNumber]); // Re-run the effect if rollNumber changes

//   const handleOptionChange = (option) => {
//     setSelectedOption(option); // Update selected option
//   };

//   const validateAnswer = () => {
//     if (selectedOption === questions[currentIndex]?.right_ans) {
//       setMarks(marks + 1); // Increment marks for correct answer
//     }
//     setAnsweredQuestions((prev) => new Set(prev.add(currentIndex))); // Mark question as answered
//   };

//   const handleSubmit = () => {
//     validateAnswer(); // Validate current question's answer
//     alert(`Test Submitted! Your total marks are: ${marks}`);
//     setIsTimerRunning(false); // Disable the exam once submitted
//   };

//   const currentQuestion = questions[currentIndex] || {}; // Safe access to current question

//   // Format the time left into mm:ss
//   const minutes = Math.floor(timeLeft / 60000);
//   const seconds = Math.floor((timeLeft % 60000) / 1000);
//   const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

//   return (
//     <div className="exam-container">
//       <div className="timer">
//         <p className="exam-timer">Time Remaining: {formattedTime}</p>
//       </div>

//       <div className="question-container">
//         {currentQuestion.question ? (
//           <>
//             <p className="question">
//               <strong>Question {currentIndex + 1}:</strong> {currentQuestion.question}
//             </p>
//             <div className="options">
//               {['opt1', 'opt2', 'opt3', 'opt4'].map((optionKey, index) => {
//                 const option = currentQuestion[optionKey];
//                 if (option) {
//                   return (
//                     <label className="question-option" key={index}>
//                       <input
//                         className='exam-option-box'
//                         type="radio"
//                         name="answer"
//                         value={option}
//                         checked={selectedOption === option}
//                         onChange={() => handleOptionChange(option)}
//                         disabled={!isTimerRunning}
//                       />
//                       {option}
//                     </label>
//                   );
//                 }
//                 return null;
//               })}
//             </div>
//           </>
//         ) : (
//           <p>Loading question...</p> // Loading state for questions
//         )}
//       </div>

//       <div className="buttons">
//         <button
//           onClick={() => {
//             validateAnswer(); // Validate the answer before moving
//             setCurrentIndex(currentIndex - 1);
//             setSelectedOption(
//               questions[currentIndex - 1] ? questions[currentIndex - 1].selectedOption : ''
//             );
//           }}
//           disabled={currentIndex === 0 || !isTimerRunning}
//         >
//           Previous
//         </button>
//         <button
//           onClick={() => {
//             validateAnswer(); // Validate the answer before moving
//             setCurrentIndex(currentIndex + 1);
//             setSelectedOption(
//               questions[currentIndex + 1] ? questions[currentIndex + 1].selectedOption : ''
//             );
//           }}
//           disabled={currentIndex === questions.length - 1 || !isTimerRunning}
//         >
//           Next
//         </button>
//         <button onClick={handleSubmit} disabled={!isTimerRunning}>
//           Submit
//         </button>
//       </div>

//       <div className="marks">Current Marks: {marks}</div>
//     </div>
//   );
// };

// export default GemExam;
