import React from 'react';
import { Link } from 'react-router-dom';
import './BillingModel.css'
import CheckImg from '../../assets/rating-img/Pointer-image.png'

const BillingModels = ({ isOpen, onClose, formData, quizDateTime }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="modal-content-billing">
        <div className="modal-content-billing-img">
            <img src={CheckImg} alt="" />
        </div>
        <div className="modal-content-billing-text">
        
        <p><b>Congratulations {formData.firstName}!</b>  <br /> You've successfully registered and completed the second step. <br /> To complete your registration process, please check your <b>E-mail</b> for the next steps.</p>
        <h3>Thank You </h3>
        </div>
        <div className="modal-content-billing-btn">
        <Link to='/'><button  onClick={onClose}>OK</button> </Link>
        </div>
      </div>

    </div>
  );
};

export default BillingModels;
