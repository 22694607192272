import React from 'react';
import './TermandCondition.css';

const TermandCondition = () => {
  return (
    <div>
        <div className="about-main-div">
        <div className="about-btn">
          <a href="/" className="about-btn-link">
            Home <span className="separator"> &gt; </span> Terms & Conditions
          </a>
        </div>
      </div>


    <div className="terms-container">
        <div className="term-childDiv">
      <h1 className="terms-heading">Terms & Conditions</h1>
      <div className="terms-content">
        <section className="section-introduction">
          <h2 className="section-heading">1.1 Introduction</h2>
          <p className="section-paragraph">
            This acceptable use policy sets out the terms between you and us under which you may access the Assocham Group websites. For the purposes of this terms of use policy “we”, “us”, “our” and “Assocham” means: Rolex Replica
          </p>
          <p className="section-paragraph">
            a) Assocham Group Limited, a company registered in India, New Delhi under company number ------------, and with a registered office of New Delhi; and b) any Assocham Group Limited subsidiary as defined under Section --------------, Companies Act ---------------.
          </p>
        </section>

        <section className="section-acceptance">
          <h2 className="section-heading">1.2 Acceptance</h2>
          <p className="section-paragraph">
            Your use of the Assocham Group websites means that you accept, and agree with terms and conditions as well as abide by Privacy Policy, Cookie Policy, and Acceptable Use Policy.
          </p>
        </section>

        <section className="section-data-protection">
          <h2 className="section-heading">2. Data Protection Act</h2>
          <p className="section-paragraph">
            The Data protection Act governs the use of personal data and outlines the legal responsibilities of data controllers and processors. To find out more about how we collect, store, use and process the data we collect, please visit our Privacy Policy.
          </p>
        </section>

        <section className="section-accuracy">
          <h2 className="section-heading">3. Accuracy</h2>
          <p className="section-paragraph">
            3.1 The content on the Assocham Group websites is only for general information. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.
          </p>
          <p className="section-paragraph">
            3.2 Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our website is accurate, complete or up to date.
          </p>
        </section>

        <section className="section-cookies">
          <h2 className="section-heading">4. Cookies</h2>
          <p className="section-paragraph">
            We use cookies to make visiting our site easier and enhance the user experience. To find out more about how we use cookies please visit our Cookie Policy.
          </p>
        </section>

        <section className="section-aggregate-data">
          <h2 className="section-heading">5. Aggregate Data</h2>
          <p className="section-paragraph">
            Uses informations are automatically stored when you visit Assocham Group websites. This includes the number and frequency of visitors to Assocham Group websites. We only use such data in aggregate form. This is collected data to help us determine how much, and which parts, of the Assocham Group websites you use, so we can improve the website user experience.
          </p>
        </section>

        <section className="section-ip-address">
          <h2 className="section-heading">6. IP Address</h2>
          <p className="section-paragraph">
            IP address of your computer is tracked to count internet traffic by Assocham Group websites. An IP address is a number assigned to your computer when you use your browser or access the internet. We log this information, but this information is never linked to any personally identifying information. It is used to audit the number of visitors to Assocham Group websites.
          </p>
        </section>

        <section className="section-disclaimer">
          <h2 className="section-heading">7. Disclaimer</h2>
          <p className="section-paragraph">
            7.1. We will not be liable to you for any (including indirect and consequential) loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, Assocham Group websites or use of or reliance on any content displayed on Assocham Group websites.
          </p>
          <p className="section-paragraph">
            7.2. Mention of third-party products, services, companies, and websites on any Assocham website is for informational purposes only and constitutes neither an endorsement nor a recommendation.
          </p>
        </section>

        <section className="section-websites-links">
          <h2 className="section-heading">8. Websites we link to</h2>
          <p className="section-paragraph">
            Where Assocham Group websites contain links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by Assocham of those linked websites or information you may obtain from them. This is because we have no control over the content of those sites or resources.
          </p>
        </section>

        <section className="section-user-generated-content">
          <h2 className="section-heading">9. User-generated content approval</h2>
          <p className="section-paragraph">
            9.1. Assocham Group websites may include information and materials uploaded by other users of the Assocham Group websites, including bulletin boards and chat rooms. This information and these materials have not been verified or approved by Assocham. The views expressed by other users on Assocham Group websites do not necessarily represent Assocham’s views or values.
          </p>
          <p className="section-paragraph">
            9.2. If you wish to complain about information and materials uploaded by other users please contact us at ----------------------------------------
          </p>
        </section>

        <section className="section-uploading-content">
          <h2 className="section-heading">10. Uploading content to Assocham Group websites</h2>
          <p className="section-paragraph">
            10.1. Whenever Assocham makes use of a feature that allows you to upload content to Assocham Group websites, or to make contact with other users of Assocham Group websites, you must comply with the content standards set out in our Acceptable Use Policy.
          </p>
          <p className="section-paragraph">
            10.2. You warrant that any such contribution complies with those standards, and you will be liable to Assocham, indemnify and hold us harmless, for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
          </p>
          <p className="section-paragraph">
            10.3. Any content you upload to Assocham Group websites will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of the Assocham Group websites a perpetual, royalty-free, worldwide, and license to use, store and copy that content and to distribute and make it available to third parties.
          </p>
          <p className="section-paragraph">
            10.4. We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to Assocham Group websites constitutes a violation of their intellectual property rights, or of their right to privacy.
          </p>
          <p className="section-paragraph">
            10.5. We have the right to remove any posting you make on Assocham Group website if, in our opinion, your post does not comply with the content standards set out in Our Acceptable Use Policy.
          </p>
          <p className="section-paragraph">
            10.6. You are solely responsible for securing and backing up your content.
          </p>
        </section>

        <section className="section-intellectual-property">
          <h2 className="section-heading">11. Intellectual Property</h2>
          <p className="section-paragraph">
            11.1. We are the owner or the licensee of all intellectual property rights in the Assocham Group websites, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
          </p>
          <p className="section-paragraph">
            11.2. You may print off, and may download extracts, of any page from any Assocham Group website for your personal use and you may draw the attention of others within your organization to content posted on Assocham Group websites.
          </p>
          <p className="section-paragraph">
            11.3. You must not modify the paper or digital copies of any materials that you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
          </p>
          <p className="section-paragraph">
            11.4. Our status (and that of any identified contributors) as the authors of content on Assocham Group websites must always be acknowledged.
          </p>
          <p className="section-paragraph">
            11.5. You must not use any part of the content on Assocham Group websites for commercial purposes without obtaining a license to do so from Assocham or our licensors.
          </p>
          <p className="section-paragraph">
            11.6. If you print off, copy or download any part of an Assocham Group website in breach of these terms of use, your right to use all of the Assocham Group websites will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
          </p>
        </section>

        <section className="section-trademarks">
          <h2 className="section-heading">12. Trademarks</h2>
          <p className="section-paragraph">
            Assocham Group brand names and certification marks are registered and protected across various countries across the world. Trademarks belonging to Assocham may not be used without express written permission from the relevant Assocham Group Company.
          </p>
        </section>

        <section className="section-governing-law">
          <h2 className="section-heading">13. Governing Law</h2>
          <p className="section-paragraph">
            These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.
          </p>
        </section>
      </div>
    </div>
    </div>
    </div>
  );
}

export default TermandCondition;
