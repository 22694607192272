
// // // new code 

// import React, { useState, useEffect } from 'react';
// import './Gallery2.css'; // Make sure to create this CSS file
// import { Link, } from 'react-router-dom';
// import img1 from '../assets/GreenBuilding/Banners 1.jpg'
// import img2 from '../assets/GreenBuilding/Banners 2.jpg'
// import img3 from '../assets/GreenBuilding/Banners 3.jpg'
// import img4 from '../assets/GreenBuilding/Banners 4.jpg'
// import img5 from '../assets/GreenBuilding/Banners 5.jpg'
// import img6 from '../assets/GreenBuilding/Banners 6.jpg'
// import img7 from '../assets/GreenBuilding/Banners 7.jpg'
// import img8 from '../assets/GreenBuilding/Banners 8.jpg'
// import img9 from '../assets/GreenBuilding/Banners 9.jpg'
// import img10 from '../assets/GreenBuilding/Banners 10.jpg'
// import img11 from '../assets/GreenBuilding/Banners 11.jpg'
// import img12 from '../assets/GreenBuilding/Banners 12.jpg'
// import img13 from '../assets/GreenBuilding/Banners 13.jpg'
// import img14 from '../assets/GreenBuilding/Banners 14.jpg'
// import img15 from '../assets/GreenBuilding/Banners 15.jpg'
// import img16 from '../assets/GreenBuilding/Banners 16.jpg'
// import img17 from '../assets/GreenBuilding/Banners 17.jpg'
// import img18 from '../assets/GreenBuilding/Banners 18.jpg'
// import img19 from '../assets/GreenBuilding/Banners 19.jpg'
// import img20 from '../assets/GreenBuilding/Banners 20.jpg'
// import img21 from '../assets/GreenBuilding/Banners 21.jpg'
// import img22 from '../assets/GreenBuilding/Banners 22.jpg'
// import img23 from '../assets/GreenBuilding/Banners 23.jpg'
// import img24 from '../assets/GreenBuilding/Banners 24.jpg'
// import img25 from '../assets/GreenBuilding/Banners 25.jpg'
// import img26 from '../assets/GreenBuilding/Banners 26.jpg'
// import img27 from '../assets/GreenBuilding/Banners 27.jpg'
// import img28 from '../assets/GreenBuilding/Banners 28.jpg'
// import img29 from '../assets/GreenBuilding/Banners 29.jpg'
// import img30 from '../assets/GreenBuilding/Banners 30.jpg'
// import img31 from '../assets/GreenBuilding/Banners 31.jpg'
// import img32 from '../assets/GreenBuilding/Banners 32.jpg'
// import img33 from '../assets/GreenBuilding/Banners 33.jpg'
// import img34 from '../assets/GreenBuilding/Banners 34.jpg'
// import img35 from '../assets/GreenBuilding/Banners 35.jpg'


// const Gallery = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const imagesPerPage = 6;

//   const cards = [
//     { id: 1, title: 'Suparshwanath, Pune', imgSrc: img1 },
//     { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', imgSrc: img2 },
//     { id: 3, title: 'Alaya Apartment, Jaipur', imgSrc: img3 },
//     { id: 4, title: 'PPI System, Vatva, Ahmedabad', imgSrc: img4 },
//     { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img5 },
//     { id: 6, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img6 },
//     { id: 7, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img7 },
//     { id: 8, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img8 },
//     { id: 9, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img9 },
//     { id: 10, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img10 },
//     { id: 11, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img11 },
//     { id: 12, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img12 },
//     { id: 13, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img13 },
//     { id: 14, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img14 },
//     { id: 15, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img15 },
//     { id: 16, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img16 },
//     { id: 17, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img17 },
//     { id: 18, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img18 },
//     { id: 19, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img19 },
//     { id: 20, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img20 },
//     { id: 21, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img21 },
//     { id: 22, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img22 },
//     { id: 23, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img23 },
//     { id: 24, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img24 },
//     { id: 25, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img25 },
//     { id: 26, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img26 },
//     { id: 27, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img27 },
//     { id: 28, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img28 },
//     { id: 29, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img29 },
//     { id: 30, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img30 },
//     { id: 31, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img31 },
//     { id: 32, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img32 },
//     { id: 33, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img33 },
//     { id: 34, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img34 },
//     { id: 35, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img35 },
//   ];
 


//   const indexOfLastImage = currentPage * imagesPerPage;
//   const indexOfFirstImage = indexOfLastImage - imagesPerPage;
//   const currentImages = cards.slice(indexOfFirstImage, indexOfLastImage);

//   const totalPages = Math.ceil(cards.length / imagesPerPage);
//   const pageNumbers = [];

//   // Calculate the range of page numbers to show
//   const maxVisiblePages = 5; // Number of page buttons to show at once
//   const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 3));
//   const endPage = Math.min(totalPages, startPage + maxVisiblePages - 3);

//   // Add page numbers with ellipsis if needed
//   for (let i = startPage; i <= endPage; i++) {
//     pageNumbers.push(i);
//   }

//   // Navigate to the specific page when clicked
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//     window.location.reload();
//   };

//   // Go to the next page
//   const nextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//       window.location.reload();
//     }
//   };

//   // Go to the previous page
//   const prevPage = () => {
//     if (currentPage > 2) {
//       setCurrentPage(currentPage - 1);
//       window.location.reload();
//     }
//   };


//   return (
//     <div>
//       <div className="gallery-main-bkg">
//         <div className="about-main-div">
//           <div className="about-btn">
//             <a href="" className="about-btn-link">
//               <Link to='/'>Home</Link> <span className="separator"> &gt; </span> GEM Green Certification
//             </a>
//           </div>
//         </div>

//         <div className="Gallery-text-paragraph">
//           <div className="Gallery-para-text-div">
//             <h3>GEM Green building certification</h3>
//             <p className="gallery-text">
//              The GEM green building certification is an indigenous systematic evidence-
//              based rating system for built environment including commercial and residential 
//              complexes. It is designed on the principles of NBC 2016, ECBC 2017 and 
//              selected CPWD guidelines. The objective is to ensure future buildings move 
//              towards environment friendly methods of design, construction, and operations. 
//              The GEM certification programme has so far successfully rated many projects 
//              across the country. The projects range from residential to high rise, mixed land 
//              use, interior, institutional, commercial, factory, hotel, hospital as well as public 
//              transportation including airports and railway stations. 
//             </p>
//             <p className="gallery-text">
//              GEM has completed more than 200 projects and is supported by an ecosystem 
//              of more than 2000 architects, MEP consultants, GEM CP, sectoral experts and 
//              others across the country. The GEM green building certification offers Pre-
//              certification (for green field/design phase or under construction) and final 
//              certification for (new and existing buildings). It offers a unique preposition for 
//              enabling tangible and indirect savings throughout the life cycle of the projects 
//              with respect to energy, materials, HVAC costs and many more aspects.
//             </p>
//           </div>
//         </div>

//         <div className="gallery-container">
//           {currentImages.map(card => (
//             <div key={card.id} className="card">
//               <img src={card.imgSrc} alt={card.title} />
//               <div className="title-overlay">
//                 {/* <h3>{card.title}</h3> */}
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="pagination">
//           {/* Previous button */}
//           <button onClick={prevPage} disabled={currentPage === 1}>
//             Previous
//           </button>

//           {/* Show the page numbers with ellipses when needed */}
//           {startPage > 1 && (
//             <>
//               <button onClick={() => handlePageChange(1)}>1</button>
//               <button><span>...</span></button>
//             </>
//           )}

//           {pageNumbers.map((number) => (
//             <button
//               key={number}
//               onClick={() => handlePageChange(number)}
//               className={currentPage === number ? 'active' : ''}
//             >
//               {number}
//             </button>
//           ))}

//           {endPage < totalPages && (
//             <>
//               <button><span>...</span></button>
//               <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
//             </>
//           )}

//           {/* Next button */}
//           <button onClick={nextPage} disabled={currentPage === totalPages}>
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Gallery;



// new code 

import React, { useState, useEffect } from 'react';
import './Gallery2.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';
import img1 from '../assets/GreenBuilding/Banners 1.jpg';
import img2 from '../assets/GreenBuilding/Banners 2.jpg';
import img3 from '../assets/GreenBuilding/Banners 3.jpg';
import img4 from '../assets/GreenBuilding/Banners 4.jpg';
import img5 from '../assets/GreenBuilding/Banners 5.jpg';
import img6 from '../assets/GreenBuilding/Banners 6.jpg';
import img7 from '../assets/GreenBuilding/Banners 7.jpg';
import img8 from '../assets/GreenBuilding/Banners 8.jpg';
import img9 from '../assets/GreenBuilding/Banners 9.jpg';
import img10 from '../assets/GreenBuilding/Banners 10.jpg';
import img11 from '../assets/GreenBuilding/Banners 11.jpg';
import img12 from '../assets/GreenBuilding/Banners 12.jpg';
import img13 from '../assets/GreenBuilding/Banners 13.jpg';
import img14 from '../assets/GreenBuilding/Banners 14.jpg';
import img15 from '../assets/GreenBuilding/Banners 15.jpg';
import img16 from '../assets/GreenBuilding/Banners 16.jpg';
import img17 from '../assets/GreenBuilding/Banners 17.jpg';
import img18 from '../assets/GreenBuilding/Banners 18.jpg';
import img19 from '../assets/GreenBuilding/Banners 19.jpg';
import img20 from '../assets/GreenBuilding/Banners 20.jpg';
import img21 from '../assets/GreenBuilding/Banners 21.jpg';
import img22 from '../assets/GreenBuilding/Banners 22.jpg';
import img23 from '../assets/GreenBuilding/Banners 23.jpg';
import img24 from '../assets/GreenBuilding/Banners 24.jpg';
import img25 from '../assets/GreenBuilding/Banners 25.jpg';
import img26 from '../assets/GreenBuilding/Banners 26.jpg';
import img27 from '../assets/GreenBuilding/Banners 27.jpg';
import img28 from '../assets/GreenBuilding/Banners 28.jpg';
import img29 from '../assets/GreenBuilding/Banners 29.jpg';
import img30 from '../assets/GreenBuilding/Banners 30.jpg';
import img31 from '../assets/GreenBuilding/Banners 31.jpg';
import img32 from '../assets/GreenBuilding/Banners 32.jpg';
import img33 from '../assets/GreenBuilding/Banners 33.jpg';
import img34 from '../assets/GreenBuilding/Banners 34.jpg';
import img35 from '../assets/GreenBuilding/Banners 35.jpg';

const Gallery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 6;

  const cards = [
    { id: 1, title: 'Suparshwanath, Pune', imgSrc: img1 },
    { id: 2, title: 'Shraddha CHS Ltd., Navi Mumbai', imgSrc: img2 },
    { id: 3, title: 'Alaya Apartment, Jaipur', imgSrc: img3 },
    { id: 4, title: 'PPI System, Vatva, Ahmedabad', imgSrc: img4 },
    { id: 5, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img5 },
    { id: 6, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img6 },
    { id: 7, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img7 },
    { id: 8, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img8 },
    { id: 9, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img9 },
    { id: 10, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img10 },
    { id: 11, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img11 },
    { id: 12, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img12 },
    { id: 13, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img13 },
    { id: 14, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img14 },
    { id: 15, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img15 },
    { id: 16, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img16 },
    { id: 17, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img17 },
    { id: 18, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img18 },
    { id: 19, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img19 },
    { id: 20, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img20 },
    { id: 21, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img21 },
    { id: 22, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img22 },
    { id: 23, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img23 },
    { id: 24, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img24 },
    { id: 25, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img25 },
    { id: 26, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img26 },
    { id: 27, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img27 },
    { id: 28, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img28 },
    { id: 29, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img29 },
    { id: 30, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img30 },
    { id: 31, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img31 },
    { id: 32, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img32 },
    { id: 33, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img33 },
    { id: 34, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img34 },
    { id: 35, title: 'Yashwin Encore +Vj Indilife, Pune', imgSrc: img35 },
  ];

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = cards.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(cards.length / imagesPerPage);
  const pageNumbers = [];

  // Calculate the range of page numbers to show
  const maxVisiblePages = 5; // Number of page buttons to show at once
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 3));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 3);

  // Add page numbers with ellipsis if needed
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Navigate to the specific page when clicked
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  // Go to the next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  };

  // Go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  };

  return (
    <div>
      <div className="gallery-main-bkg">
        <div className="about-main-div">
          <div className="about-btn">
            <a href="" className="about-btn-link">
              <Link to='/'>Home</Link> <span className="separator"> &gt; </span> GEM Green Certification
            </a>
          </div>
        </div>

        <div className="Gallery-text-paragraph">
          <div className="Gallery-para-text-div">
            <h3>GEM Green building certification</h3>
            <p className="gallery-text">
             The GEM green building certification is an indigenous systematic evidence-
             based rating system for built environment including commercial and residential 
             complexes. It is designed on the principles of NBC 2016, ECBC 2017 and 
             selected CPWD guidelines. The objective is to ensure future buildings move 
             towards environment friendly methods of design, construction, and operations. 
             The GEM certification programme has so far successfully rated many projects 
             across the country. The projects range from residential to high rise, mixed land 
             use, interior, institutional, commercial, factory, hotel, hospital as well as public 
             transportation including airports and railway stations. 
            </p>
            <p className="gallery-text">
             GEM has completed more than 200 projects and is supported by an ecosystem 
             of more than 2000 architects, MEP consultants, GEM CP, sectoral experts and 
             others across the country. The GEM green building certification offers Pre-
             certification (for green field/design phase or under construction) and final 
             certification for (new and existing buildings). It offers a unique preposition for 
             enabling tangible and indirect savings throughout the life cycle of the projects 
             with respect to energy, materials, HVAC costs and many more aspects.
            </p>
          </div>
        </div>

        <div className="gallery-container">
          {currentImages.map(card => (
            <div key={card.id} className="card">
              <img src={card.imgSrc} alt={card.title} />
              <div className="title-overlay">
                {/* <h3>{card.title}</h3> */}
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          {/* Previous button */}
          <button onClick={prevPage} disabled={currentPage === 1}>
            Previous
          </button>

          {/* Show the page numbers with ellipses when needed */}
          {startPage > 1 && (
            <>
              <button onClick={() => handlePageChange(1)}>1</button>
              <button><span>...</span></button>
            </>
          )}

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={currentPage === number ? 'active' : ''}
            >
              {number}
            </button>
          ))}

          {endPage < totalPages && (
            <>
              <button><span>...</span></button>
              <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </>
          )}

          {/* Next button */}
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
