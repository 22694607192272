import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faAnglesRight, faUser, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

// Import logo images from assets
import InstaImg from '../assets/SocialSvg/instagramImg.svg';
import facebookImg from '../assets/SocialSvg/facebookImg.svg';
import XImg from '../assets/SocialSvg/XImg.svg';
import LinkendinImag from '../assets/SocialSvg/linkedin-original.svg'
import YoutubeImg from '../assets/SocialSvg/youtube.svg'
import { faFacebook, faInstagramSquare, faTelegram, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* First div with logos and content */}
      {/* Uncomment if needed
      <div className="footer-logo-content">
        <div className="footer-logos">
          <Link to="/"><img src={logo1} alt="Logo 1" className="logo" id='img-logo' /></Link>
          <Link to="/"><img src={logo3} alt="Logo 2" className="logo" id='img-logo' /></Link>
        </div>
        <p>THE ASSOCIATED CHAMBERS OF COMMERCE & INDUSTRY OF INDIA</p>
      </div>
      */}
       <div className="footer-address">
          <h4>ASSOCHAM Headquarters</h4>
          <div className="address-div-footer">
          
          <div className="icon-div-footer">

            <div className="icon-footer-address">
          <FontAwesomeIcon icon={faLocationDot} className='contact-icon' />
          </div>

          <div className="address-line">
          <ul className='address-line-ul'>
          <li> 4th Floor, YMCA Cultural Centre and Library Building,  </li>
          
          <li>01, Jai Singh Road, New Delhi - 110001</li>
          </ul>
          </div>

</div>
           
           </div>
          <div className="social-icons">
            <div className="icon-circle"><img src={InstaImg} alt=" Instagram" /></div>
            <div className="icon-circle"><img src={facebookImg} alt="Face Book" /> </div>
            <div className="icon-circle"><img src={XImg} alt="Twitter" /> </div>
            <div className="icon-circle"><img src={LinkendinImag} alt="Linked In" /> </div>
            <div className="icon-circle"><img src={YoutubeImg} alt="Youtube" style={{height: '25px'}} /> </div>

          </div>
          </div>

<div className="footer-quick-links">
        <h4>Quick Links</h4>
        <div className="footer-quick-link-div">
        <ul className='quick-link'>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> About ASSOCHAM</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> About GEM Rating</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> GEM Rating Systems</li>
          </ul>
          <ul className='quick-link'>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> Project Registration</li>
          {/* <Link to='/faq'><li> <FontAwesomeIcon icon={faAnglesRight} /> Faq</li></Link> */}
          <li> <FontAwesomeIcon icon={faAnglesRight} /> Payment</li>
        </ul>
        </div>
      </div>



      {/* Third div with Contact Us */}
      <div className="footer-contact">
        <h4>Contact Us</h4>
        <div className="contact-list-div">
          <div className="contact-list">
            <ul className='footerAddress'>
              <li>
                <FontAwesomeIcon icon={faUser} style={{ color: "#8aaf3b" }} className="contact-icon" />
                <p> Amit Kumar Sharma</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                <a href="tel:+919352713762"><p> +91 9352713762</p></a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                <a href="mailto:amitkumar.sharma@assocham.com"><p>amitkumar.sharma@assocham.com</p></a>
              </li>
            </ul>
          </div>
          <div className="contact-list-2">
            <ul className='footerAddress'>
              <li>
                <FontAwesomeIcon icon={faUser} className="contact-icon" />
                <p> Tarun Sachdeva</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                <a href="tel:+919315700465"><p> +91 8239065445</p></a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                <a href="mailto:tarun.sachdeva@assocham.com"><p>tarun.sachdeva@assocham.com</p></a>
              </li>
            </ul>
            {/* <div className="footer-quick-links">
              <FAQ />
              </div> */}
          </div>
        </div>
      </div>

      {/* Second div with Quick Links */}
      
    </footer>
  );
};

export default Footer;
