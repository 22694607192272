import React from 'react'
import './EnviromentalServices.css';
import PointerImg from '../assets/rating-img/Pointer-image.png'
import TextFlexImg from '../assets/rating-img/enviro-flexImg.png'

const EnviromentalService = () => {
  return (
    <div>
        <div className="enviromental-bg-main">
      <div className="GEMCP-btn-link">
          <a href="">
            Home <span className="separator"> &gt; </span> Environmental services
          </a>
        </div>

        <div className="enviromental-text-main-div">
        <h3 className="enviro-title" style={{paddingBottom: '15px'}}>Environmental Services</h3>
                        
        <p className="enviro-text" style={{width: '90%', margin: 'auto'}}>The UN has recognized climate change as one of the three planetary crises, along with pollution and loss of biodiversity. The clarion call from the global leaders at the COP and other high-level platforms like G20 on more responsible production and consumption is the need of the hour for addressing climate change. The launch of LiFE (Lifestyle for Environment) by the Hon’ble Prime Minister of India, Shri Narendra Modi, is a significant step towards achieving a circular economy from individuals, groups, and community perspectives.</p>

            <p className="enviro-text" style={{width: '90%', margin: 'auto'}}>Environmental services encompass a diverse range of technical and knowledge-based services for both manufacturing and service companies. Generally, in this section, different project-based assignments are provided for an organisational benefit either in process, product or procurement systems including logistics, manufacturing, value addition and expansion stages of an organisation.</p>

            <div className="enviromental-img-flex-div">
                <div className="enviroment-text-box">
                    <div className="enviro-text-para-box">
                        
                        <p className="enviro-text">
                        {/* The environmental services extended to companies include but not limited to environmental due diligence with respect to */}
                        <p className="enviro-child-text">The environmental services extended to companies include but not limited to environmental due diligence with respect to</p>
                        <ul className="enviro-list-box">
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                    <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Energy</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Water</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Waste management</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Biodiversity assessment</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Air emissions</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Net Zero transition</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">CBAM</p>
                            </li>
                            <li className="enviro-list">
                                <div className="enviro-ponter">
                                <img src={PointerImg} alt="" />
                                </div>
                                <p className="enviro-list-text">Climate Neutral Now – UNFCCC guidelines</p>
                            </li>
                        </ul>
                        </p>
                        
                    </div>
                </div>
                <div className="enviro-img-box">
                    <img src={TextFlexImg} alt="" />
                </div>
            </div>
            <p className="enviro-text" style={{width: '90%', margin: 'auto'}}>The aim of Environmental services programme is to build a robust and sustainability movement for manufacturing and services sectors which are based on scientific principles in line with global goals of SDG 2030 especially focused on SDG 4, 6, 7, 8, 9, 11, 13 and 17. The products and ratings assist in the journey towards net zero transition for realising the Nationally Determined Contribution target of achieving net zero by 2070 for India</p>
        </div>


        </div>
    </div>
  )
}

export default EnviromentalService
