import React from 'react';
import './SocialFeed.css'; // Importing the CSS file for styles

const SocialFeed = () => {
  const posts = [
    {
      socialIconImg : 'SocialSvg/facebookImg.svg',
      imgSrc: 'SocialImg/SocialFB.jpeg',
      text: 'ASSOCHAM announces its mega flagship event GEM 5th Sustainability Conclave, Expo and Awards on the 20-21 January 2023 @TAJ MAHAL hotel, Lucknow.',
    },
    {
      socialIconImg : 'SocialSvg/XImg.svg',
      imgSrc: 'SocialImg/SocialX.jpeg',
      text: 'We are proud to have Mehta Tubes Limited as the HVAC PARTNER for ASSOCHAM GEM 5th International Sustainability Conclave, Expo & Awards 2023 scheduled to be held on Jan 20-21 at Hotel Taj Mahal, Lucknow.',
    },
    {
      socialIconImg : 'SocialSvg/linkedin-original.svg',
      imgSrc: 'SocialImg/SocialLinkedIn.jpeg',
      text: <ul>
      <li>GEM Sustainability Certification Program Reference Guide</li>
      <li>ASSOCHAM GEM Reference Guide for Existing Building</li>
      <li>GEM Certified Professional Online Exam Sample Paper</li>
    </ul>
    },
    
  ];


  return (
    <div className="social-feed-main">
      <div className="Social-feed-2ndDiv">
      <h2 id='SocialFeed-Heading'>Social Feed</h2>
<div className="postContainer-box">
    <div className="social-feed">
      {posts.map((post, index) => (
        <div className="social-box-container" key={index}>
           <div className="social-icon-img">
          <img style={{width: '35px', padding: '10px'}} src={require(`../assets/${post.socialIconImg}`)} alt={`Icon ${index + 1}`}/>
          </div>
        <div className="Socialcard" >

          <img src={require(`../assets/${post.imgSrc}`)} alt={`Social ${index + 1}`} />
          </div>
          {/* <div className="social-card-text"> */}
          {/* <p className="social-card-text">{post.text}</p> */}
         
          
        </div>
      ))}
      </div>
    </div>
    </div>
    </div>

    
  );
};

export default SocialFeed;

