import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import './CpsRegisterForm.css';

const countryStateData = {
  India: [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand',
    'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
    'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
    'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep',
    'Delhi', 'Puducherry', 'Jammu and Kashmir', 'Ladakh',
  ],
};

const ExamAppoiment = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams(); // Capture invoiceId from the URL

  // Initialize formData with empty values
  const [formData, setFormData] = useState({
    salutation: '',
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    country: 'India',
    state: '',
    city: '',
    pincode: '',
    mobile: '',
    telephone: '',
    message: '',
    examDate: '',
    examTiming: '',
    invoiceId: '',
  });

  // Store the invoiceId in local storage if it's available in the URL
  useEffect(() => {
    if (invoiceId) {
      localStorage.setItem('invoiceId', invoiceId); // Store in localStorage
      fetchFormData(invoiceId);
    }
  }, [invoiceId]);

  // Fetch form data based on invoiceId
  const fetchFormData = async (invoiceId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/billing/billinginfo/${invoiceId}`);
      console.log('Fetched data:', response.data); // Check the response
      if (response.data && response.data.data) {
        setFormData({
          invoiceId: response.data.data.invoiceId,
          salutation: response.data.data.salutation || '',
          first_name: response.data.data.first_name || '',
          last_name: response.data.data.last_name || '',
          email: response.data.data.email || '',
          address: response.data.data.address || '',
          country: response.data.data.country || 'India',
          state: response.data.data.state || '',
          city: response.data.data.city || '',
          pincode: response.data.data.pincode || '',
          mobile: response.data.data.mobile || '',
          telephone: response.data.data.telephone || '',
          message: response.data.data.message || '',
          examDate: response.data.data.examDate || '',
          examTiming: response.data.data.examTiming || '',
        });
        toast.success('Form data loaded successfully!');
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
      toast.error('Error loading form data.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.first_name) {
      toast.error('Please enter First Name');
      return;
    }

    if (!formData.last_name) {
      toast.error('Please enter Last Name');
      return;
    }

    // Retrieve invoiceId from localStorage
    const storedInvoiceId = localStorage.getItem('invoiceId');
    if (!storedInvoiceId) {
      toast.error('Invoice ID not found');
      return;
    }

    // Add the invoiceId from localStorage to the formData
    const formDataWithInvoice = {
      ...formData,
      invoiceId: storedInvoiceId,  // Including invoiceId from localStorage
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/billing/examdateandtime`, formDataWithInvoice);
      toast.success('Form submitted successfully!');
       // Redirect to the payment page
    } catch (error) {
      // If there's an error, check if it's a validation error (e.g., email already registered)
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);  // Display error message received from backend
      } else {
        // General error message
        toast.error('Error submitting form. Please try again.');
      }
    }
  };

  return (
    <div>
      <div className="GEM-form-div-bkg">
        <div className="main-form-div-popup">
          <div className="register-form">
            <form onSubmit={handleSubmit}>
              <h3 className="Gem-form-heading">Please Select Date and Timing for Your Exam.</h3>

              {/* Form Fields */}
              <div className="form-fields">
                <div className="form-fields-data">
                  <label>First Name *</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Email Id *</label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Address Line *</label>
                  <input
                    type="text"
                    placeholder="Address Line"
                    value={formData.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Country *</label>
                  <select
                    value={formData.country}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    required
                  >
                    <option value="" disabled>Select your country</option>
                    {Object.keys(countryStateData).map((countryName, index) => (
                      <option key={index} value={countryName}>{countryName}</option>
                    ))}
                  </select>
                </div>

                <div className="form-fields-data">
                  <label>State *</label>
                  <select
                    value={formData.state}
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                    required
                    disabled
                  >
                    <option value="" disabled>Select your state</option>
                    {formData.country === 'India' ? (
                      countryStateData[formData.country].map((stateName, index) => (
                        <option key={index} value={stateName}>{stateName}</option>
                      ))
                    ) : (
                      <option value="" disabled>Select a country to see states</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>City *</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Pincode *</label>
                  <input
                    type="text"
                    placeholder="Pin Code"
                    value={formData.pincode}
                    onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data flex-container">
                  <label>Mobile No. *</label>
                  <input
                    className="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.mobile}
                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Telephone No. (With STD Code)</label>
                  <input
                    type="tel"
                    placeholder="Telephone Number"
                    value={formData.telephone}
                    onChange={(e) => setFormData({ ...formData, telephone: e.target.value })}
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Exam Date *</label>
                  <input
                    type="date"
                    value={formData.examDate}
                    onChange={(e) => setFormData({ ...formData, examDate: e.target.value })}
                    min={new Date().toISOString().split("T")[0]} // Ensure future date only
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Exam Timing *</label>
                  <input
                    type="time"
                    value={formData.examTiming}
                    onChange={(e) => setFormData({ ...formData, examTiming: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="button-group-2">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ExamAppoiment;
