
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo1 from '../assets/rating-img/carbonSvg/nav-element.png';
import logo3 from '../assets/Gem_new_Logo2.png';
import logo4 from '../assets/logo-tm-removebg.png';
import logo1New from '../assets/logoImg/logo1Nav.png'
import NavLogo2 from '../assets/logoImg/nav-logo2.png'
// import NavLogo3 from '../assets/logoImg/Assocham-logoNew.png'
import NavLogo3 from '../assets/logoImg/assocham-logo-new.png'
import './NewNavbar.css';

const NewNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [activeLink, setActiveLink] = useState('/'); 

    useEffect(() => {
        const handleScroll = () => {
            const sticky = window.scrollY > 0;
            setIsSticky(sticky);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to toggle the menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Function to close the menu
    const closeMenu = () => {
        setMenuOpen(false);
    };
    
   const handleLinkClick = (path) => {
    setActiveLink(path); // Set the clicked link as active
    closeMenu(); // Close menu on mobile after clicking
};

    return (
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
            <div className="logo-container">
                {/* <Link id='img-log1' to="/"><img src={logo4} alt="Logo 1" className="logo" id='img-logo1' /></Link> */}
                {/* <Link to="/"><img src={logo3} alt="Logo 2" className="logo" id='img-logo2' /></Link> */}
                <Link to="/"><img src={logo1New} alt="Logo 2" className="logo" id='img-logo2' /></Link>
                <Link to="/"><img src={logo1} alt="Logo 2" className="logo" id='blub-element' /></Link>
            </div>

            <div
                className={`menu-icon ${menuOpen ? 'rotate' : ''}`}
                onClick={toggleMenu} // Toggle the menu on icon click
            >
                <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
            </div>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li> <Link 
                        to="/about" 
                        onClick={() => handleLinkClick('/about')}
                        className={activeLink === '/about' ? 'active' : ''}
                    >
                        About Us
                    </Link></li>
                <li><Link to="/gem-rating" 
                        onClick={() => handleLinkClick('/gem-rating')}
                        className={activeLink === '/gem-rating' ? 'active' : ''} >GEM Rating</Link></li>
                <li><Link to="/gem-chapter"  onClick={() => handleLinkClick('/gem-chapter')}
                        className={activeLink === '/gem-chapter' ? 'active' : ''}>GEM Chapters</Link></li>
                {/* <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Certification</Link></li> */}
                {/* <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Certification</Link></li> */}
                {/* <li><Link to="/Sustainability" onClick={closeMenu}>Sustainability Solutions</Link></li> */}
                {/* <li className="dropdown">
                    <Link>GEM Ecosystem</Link>
                    <ul className="dropdown-menu">
                       
                        <li><Link to="/eco-products" onClick={closeMenu}>GEM Eco-Products</Link></li>
                        <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Building Certification</Link></li>
                    </ul>
                </li> */}
                <li className="dropdown">
                    <Link >Events</Link>
                    <ul className="dropdown-menu">
                        <li><Link to="/upcoming-events" onClick={() => handleLinkClick('/upcoming-events')}
                        className={activeLink === '/upcoming-events' ? 'active' : ''} id='nav-link-dropdown'>Upcoming Events</Link></li>
                        <li><Link to="/past-events" onClick={() => handleLinkClick('/past-events')}
                        className={activeLink === '/past-events' ? 'active' : ''} id='nav-link-dropdown'>Past Events</Link></li>
                        {/* <li><Link to="/past-events" onClick={closeMenu}>Past Events</Link></li> */}
                    </ul>
                </li>
                <li className="dropdown">
                    <Link>Certified Professionals</Link>
                    <ul className="dropdown-menu">
                
                            <li><Link to="/cps" onClick={() => handleLinkClick('/cps')}
                        className={activeLink === '/cps' ? 'active' : ''} style={{ textTransform: 'none' }}id='nav-link-dropdown'>List of GEM CP</Link></li>
                            <li><Link id='nav-link-dropdown' to="/gemCp" onClick={() => handleLinkClick('/gemCp')}
                        className={activeLink === '/gemCp' ? 'active' : ''} >GEM CP Registration</Link></li>

                </ul>
                </li>
                <li><Link to="/register" onClick={() => handleLinkClick('/register')}
                        className={activeLink === '/register' ? 'active' : ''}>Register For Projects</Link></li>
                <li><Link to="/resources" onClick={() => handleLinkClick('/resources')}
                        className={activeLink === '/resources' ? 'active' : ''}>Resources</Link></li>
            </ul>
            <div className="logo-container">
            <Link to="/"><img src={logo1} alt="Logo 2" className="logo" id='blub-element-2' /></Link>

                <Link id='img-log1' to="/"><img src={NavLogo3} alt="Logo 1" className="logo" id='img-logo1' /></Link>
                {/* <Link id='img-log1' to="/"><img src={NavLogo2} alt="Logo 1" className="logo" id='img-logo1' /></Link> */}
                {/* <Link to="/"><img src={logo3} alt="Logo 2" className="logo" id='img-logo2' /></Link> */}
                {/* <Link to="/"><img src={logo1New} alt="Logo 2" className="logo" id='img-logo2' /></Link> */}
            </div>
        </nav>
    );
};

export default NewNavbar;
