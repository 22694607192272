import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/Iso.css'
import './WaterAudit.css'
import EnergyImg from '../assets/EnergyAuditImg/EnergyHeadingImg.png'
import EnergyStepImg from '../assets/EnergyAuditImg/EnergyStep.png'

const WaterAudit = () => {
  return (
    <div>
      <div className="EnergyAudit-MainDiv">
        <div className="energy-bgFilterDiv">

          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span>Energy Audit
          </a>

        <div className="EnergyAudit-ElementDiv">

       <div className="EnergyHeadingDiv">
        <h3>Energy Audit</h3>
        <div className="Energy-Img-Text-FlexBox">

          <div className="EnergyImg">
            <img src={EnergyImg} alt="" />
          </div>
          
          <div className="EnergyHeading-textDiv">
            <p className="EnergyText">An energy audit is a technical study to determine how and where energy is utilised and to identify methods for energy savings and avoid wastage wherever possible. There is now a universal acceptance of the fact that new technologies and practices, provides opportunities in the use of existing renewable energy technologies, greater efforts for energy efficiency and the dissemination of these technologies and options. 
            </p>
            <p className="EnergyText">The Govt. of India has enacted the Energy Conservation Act, 2001 with the objective to provide a regulatory framework for formulation and implementation of energy conservation strategies in the country for efficient use of energy. </p>
            <p className="EnergyText">This energy audit is carried out with the assistance of set procedures, standards and measurement techniques.</p>
          </div>
        </div>

        <div className="EnergyAudit-columnDiv">
        <div className="EnergyHeading-textDiv" style={{paddingTop: '20px', paddingBottom: '0px', fontSize: '17px'}}>
        <p className="EnergyText">This report is just one step towards achieving energy efficiency and it is worth emphasizing that an energy audit is a continuous process and efficient technologies will keep coming time to time.
        </p>
        <p className="EnergyText">The Audit Study comprise of site visit, Discussion with engineering personal, and verification of records, drawing, and related documentation needed.
        </p>
        
        </div>
        <div className="EnergyAudti-Step-Flex">
        <div className="EnergyHeading-textDiv">
        <p className="EnergyText" style={{fontWeight: 'bold'}}>
        A detailed list of referenced standards is follows: - 
        </p>
        </div>
        <div className="EnergyAudit-StepImg">
          <img src={EnergyStepImg} alt="" />
        </div>

        </div>

        <div className="EnergyHeading-textDiv">
        <p className="EnergyText">Electrical safety audit is undertaken with an intention of checking of electrical system to make it safer and to comply with the requisite engineering / electrical standards.
        </p>
        </div>

        </div>

       </div>


        </div>
        </div>
      </div>
        
  </div>
  )
}

export default WaterAudit
