
// //new code
// import React, { useState, useEffect, useRef } from 'react';
// import Gallery from './Gallery';
// import ResourceSlider from './ResourceSlider';
// import './SliderMainPage.css';
// import Resource from '../componant/Resource';
// import UpcomingEvent from '../EventsPage/UpcomingEvent';
// import UpcomingEventSilder from './UpcomingEventSilder';
// import SliderEcoProduct from './SliderEcoProduct';
// import GallerySlider from './GallerySlider';
// import TreeImg from '../assets/HomePageImg/TreeImg.png'
// import TreeImgWhite from '../assets/HomePageImg/TreeImgWhite.png'

// const SliderMainpage = () => {
//   const [activeOption, setActiveOption] = useState('GEM Green Certification');
//   const [arrowPosition, setArrowPosition] = useState(0);
//   const buttonRefs = useRef({});

//   const getContent = (option) => {
//     switch (option) {
//       case 'GEM Green Certification':
//         return <GallerySlider />;
//         // return <Gallery />;
//       case 'GEM Eco Product':
//         return <SliderEcoProduct />;
//       case 'Resource':
//         return <ResourceSlider />;
//         case 'Upcoming Events':
//           return <UpcomingEventSilder />
//       default:
//         return 'Select an option to see the content.';
//     }
//   };

//   const handleOptionChange = (option) => {
//     setActiveOption(option);
//   };

//   useEffect(() => {
//     const activeButton = buttonRefs.current[activeOption];
//     if (activeButton) {
//       const { offsetLeft, offsetWidth } = activeButton;
//       setArrowPosition(offsetLeft + offsetWidth / 2);
//     }
//   }, [activeOption]);

//   return (
//     <div className="slider-mainpage">
//       <div className="button-group">
//         {['GEM Green Certification', 'GEM Eco Product', 'Resource', 'Upcoming Events'].map((option) => (
//           <div className="Button-TreeFlexBox">
            
//           <button
//             key={option}
//             className={`button-Slider ${activeOption === option ? 'active' : ''}`}
//             onClick={() => handleOptionChange(option)}
//             ref={(el) => (buttonRefs.current[option] = el)} // Save reference to button
//             id='Slider-Option'
//           >
//             {option}
//           </button>
//           <div className="TreeImgDiv">
//             <img src={TreeImgWhite} alt="" />
//             {/* <img src={TreeImg} alt="" /> */}
//           </div>
//           </div>
//         ))}
//         <style>
//           {`
//             .button-group::after {
//               left: ${arrowPosition}px; /* Use dynamic arrow position */
//             }
//           `}
//         </style>
//       </div>
//       <div className="content">
//         <p>{getContent(activeOption)}</p>
//       </div>
//     </div>
//   );
// };

// export default SliderMainpage;

// new code
// import React, { useState, useEffect, useRef } from 'react';
// import GallerySlider from './GallerySlider';
// import SliderEcoProduct from './SliderEcoProduct';
// import ResourceSlider from './ResourceSlider';
// import UpcomingEventSilder from './UpcomingEventSilder';
// import TreeImg from '../assets/HomePageImg/TreeImg.png';
// import TreeImgWhite from '../assets/HomePageImg/TreeImgWhite.png';
// import './SliderMainPage.css';

// const SliderMainpage = () => {
//   const [activeOption, setActiveOption] = useState('GEM Green Certification');
//   const [arrowPosition, setArrowPosition] = useState(0);
//   const [hoveredOption, setHoveredOption] = useState(null); // Hover state
//   const buttonRefs = useRef({});

//   const getContent = (option) => {
//     switch (option) {
//       case 'GEM Green Certification':
//         return <GallerySlider />;
//       case 'GEM Eco Product':
//         return <SliderEcoProduct />;
//       case 'Resource':
//         return <ResourceSlider />;
//       case 'Upcoming Events':
//         return <UpcomingEventSilder />;
//       default:
//         return 'Select an option to see the content.';
//     }
//   };

//   const handleOptionChange = (option) => {
//     setActiveOption(option);
//   };

//   useEffect(() => {
//     const activeButton = buttonRefs.current[activeOption];
//     if (activeButton) {
//       const { offsetLeft, offsetWidth } = activeButton;
//       setArrowPosition(offsetLeft + offsetWidth / 2);
//     }
//   }, [activeOption]);

//   return (
//     <div className="slider-mainpage">
//       <div className="button-group">
//         {['GEM Green Certification', 'GEM Eco Product', 'Resource', 'Upcoming Events'].map((option) => (
//           <div className="Button-TreeFlexBox" key={option}>
//             <button
//               className={`button-Slider ${activeOption === option ? 'active' : ''}`}
//               onClick={() => handleOptionChange(option)}
//               ref={(el) => (buttonRefs.current[option] = el)} // Save reference to button
//               id="Slider-Option"
//               onMouseEnter={() => setHoveredOption(option)}  // Hover event
//               onMouseLeave={() => setHoveredOption(null)}  // Hover event
//             >
//               {option}
//             </button>
//             <div className="TreeImgDiv">
//               <img
//                 src={hoveredOption === option ? TreeImg : TreeImgWhite} // Change image based on hover state
//                 alt=""
//                 className="tree-img"
//               />
//             </div>
//           </div>
//         ))}
//         <style>
//           {`
//             .button-group::after {
//               left: ${arrowPosition}px; /* Use dynamic arrow position */
//             }
//           `}
//         </style>
//       </div>
//       <div className="content">
//         <p>{getContent(activeOption)}</p>
//       </div>
//     </div>
//   );
// };

// export default SliderMainpage;

// new active code 

import React, { useState, useEffect, useRef } from 'react';
import GallerySlider from './GallerySlider';
import SliderEcoProduct from './SliderEcoProduct';
import ResourceSlider from './ResourceSlider';
import UpcomingEventSilder from './UpcomingEventSilder';
import TreeImg from '../assets/HomePageImg/TreeImg.png';
import TreeImgWhite from '../assets/HomePageImg/TreeImgWhite.png';
import './SliderMainPage.css';

const SliderMainpage = () => {
  const [activeOption, setActiveOption] = useState('GEM Green Certification');
  const [arrowPosition, setArrowPosition] = useState(0);
  const [hoveredOption, setHoveredOption] = useState(null); // Hover state
  const buttonRefs = useRef({});

  const getContent = (option) => {
    switch (option) {
      case 'GEM Green Certification':
        return <GallerySlider />;
      case 'GEM Eco Product':
        return <SliderEcoProduct />;
      case 'Resource':
        return <ResourceSlider />;
      case 'Upcoming Events':
        return <UpcomingEventSilder />;
      default:
        return 'Select an option to see the content.';
    }
  };

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  useEffect(() => {
    const activeButton = buttonRefs.current[activeOption];
    if (activeButton) {
      // const { offsetLeft, offsetWidth } = activeButton;
      // setArrowPosition(offsetLeft + offsetWidth / 2);
    }
  }, [activeOption]);

  return (
    <div className="slider-mainpage">
      <div className="button-group">
        {['GEM Green Certification', 'GEM Eco Product', 'Resource', 'Upcoming Events'].map((option) => (
          <div
            className={`Button-TreeFlexBox ${activeOption === option || hoveredOption === option ? 'active' : ''}`} // Apply active class to the container
            key={option}
          >
            <button
              className={`button-SliderHome ${activeOption === option ? 'active' : ''} ${hoveredOption === option || activeOption === option ? 'active-hover' : ''}`} // Apply hover styles on the button
              onClick={() => handleOptionChange(option)}
              ref={(el) => (buttonRefs.current[option] = el)} // Save reference to button
              id="Slider-Option"
              onMouseEnter={() => setHoveredOption(option)}  // Hover event
              onMouseLeave={() => setHoveredOption(null)}  // Hover event
            >
              {option}
            </button>
            <div className="TreeImgDiv">
              <img
                src={hoveredOption === option || activeOption === option ? TreeImg : TreeImgWhite} // Change image based on hover state or active state
                alt=""
                className="tree-img"
              />
            </div>
          </div>
        ))}
        <style>
          {`
            .button-group::after {
              left: ${arrowPosition}px; /* Use dynamic arrow position */
            }
          `}
        </style>
      </div>
      <div className="content">
        <p>{getContent(activeOption)}</p>
      </div>
    </div>
  );
};

export default SliderMainpage;
